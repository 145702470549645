import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Wrapper } from '../../components';
import { Logo } from '../../components/Logo/Logo';
import { LinkIconButton } from '../../compositions';
import { ConnectedCartSideBar } from '../../connectors';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTrans from '../../hooks/useTrans';

import './MenuBar.scss';

interface MenuBarProps {
    className?: string;
}

export const MenuBar: FC<MenuBarProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const accountPath = trans(appRoutes[AppRoute.account].path);
    const dashboardUrl = replaceUrlParamKeysWithValues(accountPath, { slug: 'dashboard' });

    return (
        <div className={`menu-bar ${className}`}>
            <Wrapper>
                <div className="menu-bar--mobile">
                    <div className="menu-bar__wrapper">
                        <Link to={trans(appRoutes[AppRoute.home].path)}>
                            <Logo className="menu-bar__logo" />
                        </Link>

                        <div className="menu-bar__buttons">
                            <ConnectedCartSideBar />
                        </div>
                    </div>
                </div>

                <div className="menu-bar--desktop">
                    <div className="menu-bar__wrapper">
                        <div className="menu-bar__navigation">
                            {trans('compositions.menuBar.categories')}
                        </div>

                        <Link to={trans(appRoutes[AppRoute.home].path)}>
                            <Logo className="menu-bar__logo" />
                        </Link>

                        <div className="menu-bar__buttons">
                            <LinkIconButton
                                hideLabel
                                to={dashboardUrl}
                                icon="user"
                                text={trans('compositions.menuBar.accountDashboard')}
                                className="menu-bar__link"
                                iconClassName="menu-bar__link-icon"
                            />

                            <LinkIconButton
                                hideLabel
                                to={trans(appRoutes[AppRoute.wishlist].path)}
                                icon="heart"
                                text={trans('compositions.menuBar.wishlist')}
                                className="menu-bar__link"
                                iconClassName="menu-bar__link-icon"
                            />

                            <ConnectedCartSideBar />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};
