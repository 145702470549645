import { FC, ReactElement } from 'react';

import { ProductCard } from '../../../../compositions';
import { Product } from '../../../../entities/Product/Product';

interface InstantSearchHitProps {
    hit: Product;
    className?: string;
}

export const InstantSearchHit: FC<InstantSearchHitProps> = ({
    hit,
    className = '',
}): ReactElement => (
    <div className={`instant-search-hit ${className}`}>
        <ProductCard product={hit} />
    </div>
);
