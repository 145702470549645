import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { ArticleCard } from '../../../compositions';
import { RelatedArticlesInterface } from '../../../entities/@blocks/RelatedArticles/RelatedArticles';

import './RelatedArticles.scss';

export interface RelatedArticlesProps extends RelatedArticlesInterface {
    className?: string;
}

export const RelatedArticles: FC<RelatedArticlesProps> = ({
    articles,
    title,
    className = '',
}): ReactElement => (
    <div className={`related-articles ${className}`}>
        <Wrapper className="related-articles__wrapper">
            {title && (
                <h2 className="related-articles__title">
                    {title}
                </h2>
            )}

            <div className="related-articles__articles-wrapper">
                {articles.map(article => (
                    <ArticleCard
                        key={article.id}
                        article={article}
                    />
                ))}
            </div>
        </Wrapper>
    </div>
);
