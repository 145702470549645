import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../compositions';
import { Logo } from '../../../../../entities/@blocks/BrandsCarousel/BrandsCarousel';
import useTrans from '../../../../../hooks/useTrans';

import './BrandsCarouselItems.scss';

interface brandsCarouselItemsProps {
    logos: Logo[];
    className?: string;
}

export const BrandsCarouselItems: FC<brandsCarouselItemsProps> = ({
    logos,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`brands-carousel-items ${className}`}>
            {logos.map(logo => (
                <Link
                    key={logo.id}
                    to={logo.link.href}
                    target={logo.link.target}
                    title={logo.name}
                    className="brands-carousel-items__link"
                >
                    <Picture
                        src={logo.image.src}
                        alt={trans('containers.brandsCarousel.logoAlt', { name: logo.name })}
                        className="brands-carousel-items__picture"
                    />
                </Link>
            ))}
        </div>
    );
};
