import { ShopMapLocation } from '../../entities/Map/Map';

export const MOCK_SHOP_LOCATIONS: ShopMapLocation[] = [
    {
        label: 'Loods 5 Amersfoort',
        location: { latitude: 52.1937394, longitude: 5.4051965 },
        address: 'Euroweg 1, 3825 AH Amersfoort',
        routeLink: '/winkels/amersfoort',
    },
    {
        label: 'Loods 5 Duiven',
        location: { latitude: 51.9688173, longitude: 5.99024 },
        address: 'Cartograaf 14, 6921 EZ Duiven',
        routeLink: '/winkels/duiven',
    },
    {
        label: 'Loods 5 Maastricht',
        location: { latitude: 50.8572175, longitude: 5.6872059 },
        address: 'Sphinxcour 5, 6211 XZ Maastricht',
        routeLink: '/winkels/maastricht',
    },
    {
        label: 'Loods 5 Sliedrecht',
        location: { latitude: 51.830404, longitude: 4.7595117 },
        address: 'Leeghwaterstraat 69, 3364 AE Sliedrecht',
        routeLink: '/winkels/sliedrecht',
    },
    {
        label: 'Loods 5 Zaandam',
        location: { latitude: 52.4292552, longitude: 4.8210911 },
        address: 'Pieter Ghijsenlaan 14b, 1506 PV Zaandam',
        routeLink: '/winkels/zaandam',
    },
    {
        label: 'Loods 5 Garage Zaandam (outlet)',
        location: { latitude: 52.4290282, longitude: 4.8199311 },
        address: 'Stormhoek 20, 1506 SW Zaandam',
        routeLink: '/winkels/zaandam-outlet',
    },
];
