import { FC, ReactElement, useState } from 'react';

import { Cart } from '../../containers/Cart/Cart';
import { CartItem } from '../../entities/Cart/Cart';
import { MOCK_PRODUCT_1 } from '../../mock/mock-data';

export const ConnectedCart: FC = (): ReactElement => {
    const cartData: CartItem[] = [
        {
            product: MOCK_PRODUCT_1,
            quantity: 1,
        },
    ];

    const [removingCartItemId, setRemovingCartItemId] = useState<string | null>(null);

    // TODO: Fetch cart data from API
    // TODO: Add query to add product to cart
    // TODO: Add query to remove product to cart
    // TODO: Add query to get total price of cart

    const handleRemoveFromCart = (productId: string) => {
        console.log('handleRemoveFromCart', productId);

        setRemovingCartItemId(null);
    };

    return (
        <Cart
            cartData={cartData}
            totalPrice={124.95}
            onRemoveFromCart={handleRemoveFromCart}
            removingCartItemId={removingCartItemId}
        />
    );
};
