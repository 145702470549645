import { FaqItem } from '../../entities/Faq/Faq';

export const MOCK_FAQ_ITEMS: FaqItem[] = [
    {
        question: 'Hoe kan ik mijn bestelling volgen?',
        answer: 'Je ontvangt een e-mail met een track & trace link zodra je bestelling is verzonden. Hiermee kun je je bestelling volgen.',
    },
    {
        question: 'Wat zijn de verzendkosten?',
        answer: 'De verzendkosten bedragen €3,95 voor bestellingen onder de €50. Bestellingen boven de €50 worden gratis verzonden.',
    },
    {
        question: 'Hoe kan ik mijn bestelling retourneren?',
        answer: 'Je kunt je bestelling binnen 14 dagen na ontvangst retourneren. Neem contact op met onze klantenservice voor de retourinstructies.',
    },
];
