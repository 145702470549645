import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchableOption } from '../../entities/Form/Form';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type MapLocationState = AsyncReduxState<{
    locations: SearchableOption[];
}>;

const initialState: MapLocationState = {
    ...initialAsyncReduxState,
    locations: [],
};

const mapLocationSlice = createSlice({
    name: 'mapLocationSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setLocations(state, action: PayloadAction<SearchableOption[]>) {
            return {
                ...state,
                locations: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>) {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setLocations,
    setError,
} = mapLocationSlice.actions;

export default mapLocationSlice;
