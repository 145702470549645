import { FC, ReactElement } from 'react';

import { UspSection } from '../../../../compositions';

interface UspsExamplesProps {
    className?: string;
}

export const UspSectionExamples: FC<UspsExamplesProps> = ({
    className = '',
}): ReactElement => (
    <div className={`usp-examples ${className}`}>
        <UspSection items={[
            {
                id: 0,
                text: 'Online besteld? Gratis verzonden',
                icon: 'cart-truck',
            },
            {
                id: 1,
                text: 'Keuze uit meer dan 300 meubelmerken',
                icon: 'lamp',
            },
            {
                id: 2,
                text: 'Niet tevreden? Geld terug!',
                icon: 'happy',
            },
        ]}
        />
    </div>
);
