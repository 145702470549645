import { FC, ReactElement } from 'react';

import { Price } from '../../../../components';
import { Button, Picture } from '../../../../compositions';
import { CartItem } from '../../../../entities/Cart/Cart';
import useTrans from '../../../../hooks/useTrans';

import './CartProduct.scss';

interface CartItemProps extends CartItem {
    onRemoveFromCart: (productId: string) => void;
    className?: string;
}

export const CartProduct: FC<CartItemProps> = ({
    product,
    quantity,
    onRemoveFromCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const handleRemoveFromCart = (): void => {
        onRemoveFromCart(product.id);
    };

    return (
        <div key={product.id} className={`cart-product ${className}`}>
            <div className="cart-product__content">
                <Picture
                    src={product.images[0].src}
                    alt={product.images[0].alt}
                    className="cart-product__image"
                />

                <p>
                    {quantity}
                    x
                </p>

                <p className="cart-product__name">
                    {product.name}
                </p>

                <Price
                    amount={product.price}
                    className="cart-product__price"
                />
            </div>

            <Button
                text={trans('common.delete')}
                onClick={handleRemoveFromCart}
                className="cart-product__remove-from-cart"
            />
        </div>
    );
};
