import { ChangeEvent, FC, ReactElement } from 'react';

import {
    Button,
    Input,
    InputLabelProps,
    InputLabelWrapper,
    InputProps,
} from '../..';

import './CouponInput.scss';

interface CouponInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    buttonLabel: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
}

export const CouponInput: FC<CouponInputProps> = ({
    label,
    hideLabel,
    buttonLabel,
    required,
    tooltip,
    error,
    disabled,
    onChange,
    onSubmit,
    className = '',
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label aria-label={label} className={`coupon-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
            >
                <div className="coupon-input__field-wrapper">
                    <Input
                        {...inputProps}
                        required={required}
                        error={error}
                        disabled={disabled}
                        onChange={handleChange}
                        fieldClassName="coupon-input__field"
                    />

                    <Button
                        text={buttonLabel}
                        disabled={disabled}
                        onClick={onSubmit}
                        className="coupon-input__visibility-button"
                    />
                </div>
            </InputLabelWrapper>
        </label>
    );
};
