'use client';

import { FC, useState } from 'react';

import { Product } from '../../entities/Product/Product';
import useTrans from '../../hooks/useTrans';
import { Button } from '../@buttons/Button/Button';
import { FavouriteButton } from '../@buttons/FavouriteButton/FavouriteButton';

import './ProductCallToAction.scss';

interface ProductCallToActionProps {
    product: Product;
    className?: string;
}

export const ProductCallToAction: FC<ProductCallToActionProps> = ({
    // eslint-disable-next-line
    product,
    className = '',
}) => {
    const trans = useTrans();

    const [wishlistState, setWishlistState] = useState(false);

    return (
        <div className={`product-call-to-action ${className}`}>
            <Button
                text={trans('common.addToCart')}
                className="product-call-to-action__cart-button"
            />
            <FavouriteButton
                text={trans('common.addToWishlist')}
                isActive={wishlistState}
                onClick={() => setWishlistState(!wishlistState)}
                className="product-call-to-action__wishlist-button"
            />
        </div>
    );
};
