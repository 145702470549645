import { RouteData } from '../../LocalizedRoutes';

export enum AppRoute {
    articleDetail = 'routes.articleDetail',
    brandDetail = 'routes.brandDetail',
    contentBlocks = 'routes.contentBlocks',
    cart = 'routes.cart',
    contact = 'routes.contact',
    account = 'routes.account',
    home = 'routes.home',
    homeWithInteractiveProductHeader = 'routes.homeWithInteractiveProductHeader',
    index = 'routes.index',
    inspiration = 'routes.inspiration',
    notFound = 'routes.notFound',
    productCategory = 'routes.productCategory',
    productDetail = 'routes.productDetail',
    search = 'routes.search',
    styleguide = 'routes.styleguide',
    wishlist = 'routes.wishlist',
}

export const appRoutes = Object.fromEntries<RouteData<AppRoute>>(
    Object.entries<string>(AppRoute).map(([, value]) => ([value, {
        key: value as AppRoute,
        label: `${value}.label`,
        path: `${value}.path`,
    }])),
);
