import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { FaqItem as FaqItemData } from '../../entities/Faq/Faq';
import { normalizeString } from '../../helpers/string';
import useTrans from '../../hooks/useTrans';
import { Input } from '../@inputs/Input/Input';
import { FaqItem } from './subcomponents';

import './FaqList.scss';

const MIN_SEARCH_LENGTH = 2;

interface FaqListProps {
    faqItems: FaqItemData[];
    className?: string;
}

export const FaqList: FC<FaqListProps> = ({
    faqItems,
    className = '',
}): ReactElement => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFaqItems, setFilteredFaqItems] = useState(faqItems);

    const trans = useTrans();

    const handleInput = (event: FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearchTerm(value);

        if (value.length < MIN_SEARCH_LENGTH) {
            setFilteredFaqItems(faqItems);
            return;
        }

        const searchTerms = value.trim().split(' ');
        let filteredItems = faqItems;

        searchTerms.forEach(term => {
            const normalizedTerm = normalizeString(term.toLowerCase());

            filteredItems = filteredItems.filter((item) => {
                const question = normalizeString(item.question.toLowerCase());
                const answer = normalizeString(item.answer.toLowerCase());

                return question.includes(normalizedTerm) || answer.includes(normalizedTerm);
            });
        });

        setFilteredFaqItems(filteredItems);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <div className={`faq-list ${className}`}>
            <form enterKeyHint="search" onSubmit={handleSubmit}>
                <Input
                    hasUnderline
                    type="search"
                    icon="search"
                    placeholder={trans('compositions.faq.searchPlaceholder')}
                    value={searchTerm}
                    onInput={handleInput}
                    className="faq-list__search"
                    fieldClassName="faq-list__search-input"
                />
            </form>

            <ul className="faq-list__list">
                {filteredFaqItems.map(item => (
                    <FaqItem
                        {...item}
                        key={item.question}
                        className="faq-list__item"
                    />
                ))}
            </ul>
        </div>
    );
};
