import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { UspSection } from '../../compositions';
import { ServiceSection } from '../../compositions/ServiceSection/ServiceSection';
import { SubFooter } from '../../containers';
import { InformationGrid } from '../../containers/@blocks/InformationGrid/InformationGrid';
import { Faq } from '../../containers/Faq/Faq';
import { SubFooterFormType } from '../../entities/@blocks/SubFooter/SubFooter';
import useTrans from '../../hooks/useTrans';
import { MOCK_FAQ_ITEMS, MOCK_INFORMATION_ITEMS } from '../../mock/mock-data';
import { MOCK_SERVICE_CONTACT_DETAILS } from '../../mock/mock-data/contact';

import './ContactPage.scss';

export const ContactPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="contact-page">
            <section className="contact-page__section">
                <Wrapper className="contact-page__wrapper">
                    <h2 className="contact-page__section-header">
                        {trans('pages.contact.customerService')}
                    </h2>
                    <Faq
                        faqItems={MOCK_FAQ_ITEMS}
                        showCallToActionToAllFaqs
                    />
                </Wrapper>
            </section>
            <section className="contact-page__section">
                <Wrapper className="contact-page__wrapper">
                    <h2 className="contact-page__section-header">
                        {trans('pages.contact.informationGridHeader')}
                    </h2>
                    <InformationGrid
                        items={MOCK_INFORMATION_ITEMS}
                    />
                </Wrapper>
            </section>
            <section className="contact-page__section">
                <Wrapper className="contact-page__wrapper">
                    <h2 className="contact-page__section-header">
                        {trans('pages.contact.contactFormHeader')}
                    </h2>
                    [contact form here]
                </Wrapper>
            </section>

            <UspSection
                items={[
                    {
                        id: 0,
                        text: 'Online besteld? Gratis verzonden',
                        icon: 'cart-truck',
                    },
                    {
                        id: 1,
                        text: 'Keuze uit meer dan 300 meubelmerken',
                        icon: 'lamp',
                    },
                    {
                        id: 2,
                        text: 'Niet tevreden? Geld terug!',
                        icon: 'happy',
                    },
                ]}
            />

            <div className="contact-page__service-section">
                <Wrapper className="contact-page__wrapper">
                    <ServiceSection serviceContactDetails={MOCK_SERVICE_CONTACT_DETAILS} />
                </Wrapper>
            </div>

            <SubFooter
                title="Wekelijks inspiratie in je inbox?"
                text="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                backgroundColor="#E9EAE7"
                textColor="#2C2C30"
                form={SubFooterFormType.newsletter}
                className="contact-page__section contact-page__sub-footer"
            />
        </Page>
    );
};
