import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import classNames from 'classnames';

import { Icon } from '../../../components';
import { generateIdArray } from '../../../helpers/array';
import { Button, ButtonProps } from '../Button/Button';

import './FavouriteButton.scss';

interface FavouriteButtonProps extends Omit<ButtonProps, 'onClick'> {
    isActive?: boolean;
    onClick: (isActive: boolean) => void;
}

export const FavouriteButton: ForwardRefExoticComponent<FavouriteButtonProps> = forwardRef(({
    isActive,
    text,
    onClick,
    className = '',
    ...buttonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const animationIcons = generateIdArray(3);

    const handleClick = (): void => {
        onClick(!isActive);
    };

    const favouriteButtonClassNames = classNames('favourite-button', {
        'favourite-button--is-active': isActive,
    }, className);

    return (
        <Button
            {...buttonProps}
            ref={ref}
            text={text}
            onClick={handleClick}
            className={favouriteButtonClassNames}
        >
            <div className="favourite-button__icon-wrapper">
                <Icon
                    name="heart"
                    className="favourite-button__icon favourite-button__icon--main"
                />
                <Icon
                    name="heart"
                    className="favourite-button__icon favourite-button__icon--fill"
                    pathClassName="favourite-button__icon-path"
                />

                <div className="favourite-button__animation-wrapper">
                    {animationIcons.map(icon => (
                        <Icon
                            key={icon}
                            name="heart"
                            className="favourite-button__icon favourite-button__icon--animation"
                            pathClassName="favourite-button__icon-path"
                        />
                    ))}
                </div>
            </div>
        </Button>
    );
});
