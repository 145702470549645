import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Price, Quote } from '../../../../components';
import { QuoteSize } from '../../../../entities/Quote/Quote';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import useTrans from '../../../../hooks/useTrans';

import './TypographyExamples.scss';

interface TypographyExamplesProps {
    className?: string;
}

export const TypographyExamples: FC<TypographyExamplesProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`typography-examples ${className}`}>
            <div className="typography-examples__section">
                <h1 className="typography-examples__item typography-examples__heading typography-examples__heading--1">
                    This is heading h1
                </h1>

                <h2 className="typography-examples__item typography-examples__heading typography-examples__heading--2">
                    This is heading h2
                </h2>

                <h3 className="typography-examples__item typography-examples__heading typography-examples__heading--3">
                    This is heading h3
                </h3>

                <h3 className="typography-examples__item typography-examples__heading typography-examples__heading--3">
                    {'This is an example of a '}
                    <em>playful</em>
                    {' heading'}
                </h3>

                <h4 className="typography-examples__item typography-examples__heading typography-examples__heading--4">
                    This is heading h4
                </h4>

                <h5 className="typography-examples__item typography-examples__heading typography-examples__heading--5">
                    This is heading h5
                </h5>

                <h6 className="typography-examples__item typography-examples__heading typography-examples__heading--6">
                    This is heading h6
                </h6>

                <p className="typography-examples__item typography-examples__heading typography-examples__heading--7">
                    This is heading h7
                </p>
            </div>

            <div className="typography-examples__section">
                <Quote
                    text="This is a small quote"
                    className="typography-examples__item"
                />

                <Quote
                    text="This is a large quote"
                    size={QuoteSize.large}
                    className="typography-examples__item"
                />

                <Price
                    amount={0.00}
                    className="typography-examples__item"
                />

                <Price
                    amount={10.95}
                    className="typography-examples__item"
                />
            </div>

            <div className="typography-examples__section">
                <Link
                    to={trans(appRoutes[AppRoute.home].path)}
                    className="typography-examples__item typography-examples__link"
                >
                    About link
                </Link>

                <Link
                    to={trans(appRoutes[AppRoute.home].path)}
                    className="typography-examples__item typography-examples__link typography-examples__link--is-small"
                >
                    About link (small)
                </Link>
            </div>
        </div>
    );
};
