import { Article } from '../../entities/Article/Article';
import { MOCK_IMAGE_4, MOCK_IMAGE_5, MOCK_IMAGE_6 } from './media';

export const MOCK_ARTICLES: Article[] = [
    {
        id: '1',
        slug: 'bold-bijzonder-van-binnen-naar-buiten',
        title: 'Bold & bijzonder - van binnen naar buiten',
        intro: 'Planten zijn onderdeel van je interieur en in deze wereld van groen vind je natuurlijk ook trends.',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishDate: '2024-04-10T14:30:00.000Z',
        image: MOCK_IMAGE_6,
    },
    {
        id: '2',
        slug: 'zo-dek-je-een-sfeervolle-paastafel',
        title: 'Zo dek je een sfeervolle paastafel',
        intro: 'Planten zijn onderdeel van je interieur en in deze wereld van groen vind je natuurlijk ook trends.',
        tag: {
            slug: 'nieuws',
            name: 'Nieuws',
        },
        publishDate: '2024-02-06T14:30:00.000Z',
        image: MOCK_IMAGE_5,
    },
    {
        id: '3',
        slug: '5-items-voor-tuin-balkon',
        title: '5 items voor tuin & balkon',
        intro: 'Planten zijn onderdeel van je interieur en in deze wereld van groen vind je natuurlijk ook trends.',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishDate: '2023-10-27T14:30:00.000Z',
        image: MOCK_IMAGE_4,
    },
    {
        id: '4',
        slug: 'bold-bijzonder-van-binnen-naar-buiten',
        title: 'Bold & bijzonder - van binnen naar buiten',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishDate: '2024-04-10T14:30:00.000Z',
        image: MOCK_IMAGE_6,
    },
    {
        id: '5',
        slug: 'zo-dek-je-een-sfeervolle-paastafel',
        title: 'Zo dek je een sfeervolle paastafel',
        tag: {
            slug: 'nieuws',
            name: 'Nieuws',
        },
        publishDate: '2024-02-06T14:30:00.000Z',
        image: MOCK_IMAGE_5,
    },
    {
        id: '6',
        slug: '5-items-voor-tuin-balkon',
        title: '5 items voor tuin & balkon',
        tag: {
            slug: 'trendatelier',
            name: 'Trendatelier',
        },
        publishDate: '2023-10-27T14:30:00.000Z',
        image: MOCK_IMAGE_4,
    },
];
