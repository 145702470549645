import {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { AnchorPosition } from '../../entities/Alignment/Alignment';
import { ToastInterface } from '../../entities/Toast/Toast';
import useTimeout from '../../hooks/useTimeout';
import useTrans from '../../hooks/useTrans';
import { IconButton } from '..';

import './Toast.scss';

interface ToastProps extends ToastInterface {
    shouldClose?: boolean;
    hideCloseButton?: boolean;
    onClose?: () => void;
    className?: string;
}

export const Toast: FC<PropsWithChildren<ToastProps>> = ({
    shouldClose,
    hideCloseButton,
    revealDuration,
    transitionDuration = 300,
    position = AnchorPosition.blockEndInlineEnd,
    state,
    title,
    description,
    onClose,
    className = '',
    children,
}): ReactElement => {
    const trans = useTrans();

    // This delay is needed for the isRevealed transition to occur consistently
    const isRenderedDelay = 10;

    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const [inCloseTransition, setInCloseTransition] = useState<boolean>(false);
    const [revealDurationEnded, setRevealDurationEnded] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(false);

    useTimeout((): void => {
        setIsRevealed(true);
    }, isRenderedDelay);

    useEffect((): void => {
        if (shouldClose) {
            setInCloseTransition(true);
        }
    }, [shouldClose]);

    useEffect((): () => void => {
        let timer: NodeJS.Timeout;

        if (inCloseTransition && onClose) {
            setIsRevealed(false);
            timer = setTimeout(onClose, transitionDuration);
        }

        return (): void => {
            setIsRevealed(true);
            clearTimeout(timer);
        };
    }, [inCloseTransition, onClose, transitionDuration]);

    useTimeout((): void => {
        if (revealDuration) {
            setRevealDurationEnded(true);
        }
    }, revealDuration, [revealDuration]);

    useEffect((): void => {
        if (revealDurationEnded && !isHovering) {
            setInCloseTransition(true);
        }
    }, [revealDurationEnded, isHovering]);

    const handleMouseEnter = (): void => setIsHovering(true);
    const handleMouseLeave = (): void => setIsHovering(false);

    const handleClose = (): void => setInCloseTransition(true);

    const cssVariables = {
        '--toast-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const toastClassNames = classNames('toast', {
        'toast--inset-block-start': position.startsWith('block-start-'),
        'toast--inset-block-end': position.startsWith('block-end-'),
        'toast--inset-inline-start': position.endsWith('-inline-start'),
        'toast--inset-inline-end': position.endsWith('-inline-end'),
        [`toast--${state}`]: state,
        'toast--is-revealed': isRevealed,
    }, className);

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={cssVariables}
            className={toastClassNames}
        >
            <div className="toast__message-wrapper">
                <h2 className="toast__title">
                    {title}
                </h2>

                {description && (
                    <p className="toast__description">
                        {description}
                    </p>
                )}

                {children}
            </div>

            {!hideCloseButton && (
                <IconButton
                    icon="cross"
                    text={trans('common.close')}
                    onClick={handleClose}
                    className="toast__close-button"
                    iconClassName="toast__close-icon"
                />
            )}
        </div>
    );
};
