import { FC, ReactElement } from 'react';

import { Breadcrumbs, Page, Wrapper } from '../../components';
import { ConnectedInstantSearch } from '../../connectors';
import { CategoryHeader, SeoProductsCategoryBlock } from '../../containers';
import { MOCK_IMAGE_7 } from '../../mock/mock-data';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => (
    <Page className="search-page">
        <Wrapper>
            <Breadcrumbs
                breadcrumbs={[
                    { title: 'Home', url: '/' },
                    { title: 'Verlichting', url: '/verlichting' },
                    { title: 'Tafel- & Bureaulampen', url: '' },
                ]}
            />
        </Wrapper>

        <CategoryHeader
            title="Tafel- & bureaulampen"
            image={MOCK_IMAGE_7}
            className="category-page__image-text-block"
        />

        <Wrapper>
            <ConnectedInstantSearch />

            <SeoProductsCategoryBlock
                /* eslint-disable-next-line max-len */
                productsDescription="De beste plafondlampen, hanglampen, wandlampen, vloerlampen, tafel- en bureaulampen, spots, kids verlochting en buitenverlichting vind je bij Loods 5! Lorem ipsum dolor sit amet consectetur. Pretium mattis arcu vulputate arcu in. Amet orci ante gravida enim viverra. Urna pulvinar justo in ut. Adipiscing pretium suspendisse vitae amet et id vitae at feugiat. Eget sed iaculis pharetra ipsum enim orci."
            />
        </Wrapper>
    </Page>
);
