import { FC, ReactElement } from 'react';

import { ContentBlocksBrandAndArticleRenderer } from '../../containers/ContentBlocksBrandAndArticleRenderer/ContentBlocksBrandAndArticleRenderer';
import { FeaturedProductsType } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { ContentBlock } from '../../entities/ContentBlocks/ContentBlocks';
import {
    MOCK_IMAGE_1,
    MOCK_IMAGE_2,
    MOCK_IMAGE_3,
    MOCK_IMAGE_4,
    MOCK_IMAGE_5,
    MOCK_PRODUCTS,
} from '../../mock/mock-data';

export const ConnectedArticleDetailContentBlocks: FC = (): ReactElement => {
    //  TODO: Fetch content blocks from API

    const mockArticleDetailContentBlocks = [
        {
            type: ContentBlock.header,
            showBackButton: true,
            image: MOCK_IMAGE_1,
            title: '5 trends voor een groen interieur',
            intro: 'Planten zijn onderdeel van je interieur en in deze wereld van groen vind je',
        },
        {
            type: ContentBlock.contentColumns,
            columns: [
                {
                    id: 1,
                    content: {
                        html: '<h6>Trend 1: Imperfecte en rauwe randen</h6><p>De trend van kamerplanten'
                                + ' werd jarenlang gedomineerd door de Monstera en de Strelitzia. Twee '
                                + 'soorten die in vrijwel elk interieurstijl prima geïntegreerd kan worden. '
                                + 'De een wat makkelijker in verzorging dan de ander maar beiden een esthetisch'
                                + ' uiterlijk met gigantische bladeren. Een instant woekerend urban jungle gevoel'
                                + ' krijg je ervan. Maar er is een switch gaande. Want we willen nog steeds '
                                + 'de natuur in huis halen, maar juist het rauwe, het imperfecte. We gaan dus'
                                + ' van wild, lush en groen, naar rauw, imperfect en vergrijsd.</p>',
                    },
                },
            ],
        },
        {
            type: ContentBlock.contentColumns,
            columns: [
                {
                    id: 2,
                    content: {
                        html: '<h6>Trend 2: Haal een boom in huis</h6><p>Een indoor boom waarmee je'
                                + ' letterlijk én esthetische gezien gelijk dat rauwe en imperfecte outdoor'
                                + ' gevoel naar binnenhaalt en perfect aansluit bij de trending interieurstijlen. '
                                + 'Een daarvan is de bucida buceras. Een prachtige indoor boom en gemakkelijk in '
                                + 'onderhoud. Deze boom hangt door zijn organische en imperfecte vorm aan de Wabi '
                                + 'Sabi & Japandi trend die de interieur wereld domineert. Een van de trends '
                                + 'gericht op die natuur in huis halen, en zo imperfect mogelijk.</p>',
                    },
                },
            ],
        },
        {
            type: ContentBlock.carousel,
            id: 'carousel-example',
            images: [
                MOCK_IMAGE_1,
                MOCK_IMAGE_2,
                MOCK_IMAGE_3,
                MOCK_IMAGE_4,
                MOCK_IMAGE_5,
            ],
        },
        {
            type: ContentBlock.contentColumns,
            columns: [
                {
                    id: 3,
                    content: {
                        html: '<h6>Trend 3: Maak het niet te moeilijk</h6><p>Ook plantenpotten gaan helemaal'
                                + ' mee in deze trends, de trend waarin waar we die ruwe natuur in huis halen. '
                                + 'Direct met planten, maar ook zeker indirect met materialen en kleuren. Zo ook'
                                + ' voor de potten waar we de planten in plaatsen. Organische, afgeronde en '
                                + 'imperfecte vormen met een ambachtelijk uiterlijk in natuurlijke kleuren en'
                                + ' afwerkingen is wat dit jaar gaat domineren. Kleuren als beige, greige en grijs'
                                + ' en afwerkingen als beton, natuursteen of een handgemaakte afwerking is wat we '
                                + 'hier zien.</p>',
                    },
                },
            ],
        },
        {
            type: ContentBlock.contentColumns,
            columns: [
                {
                    id: 4,
                    content: {
                        html: '<p>Zin om deze trends in huis te halen? Shop alles voor een groen interieur '
                            + 'hieronder en kom langs in Loods 5 Maastricht, Sliedrecht of Zaandam voor de mooiste planten.</p>',
                    },
                },
            ],
        },
        {
            type: ContentBlock.featuredProducts,
            layoutType: FeaturedProductsType.verticalTitle,
            title: 'Featured Products',
            products: MOCK_PRODUCTS,
        },
    ];

    return (
        <ContentBlocksBrandAndArticleRenderer contentBlocksData={mockArticleDetailContentBlocks} />
    );
};
