import { FC, ReactElement } from 'react';

import { Icon, SafeHtml } from '../../../components';
import { InformationGridInterface } from '../../../entities/@blocks/InformationGrid/InformationGrid';

import './InformationGrid.scss';

interface InformationGridProps extends InformationGridInterface {
    className?: string;
}

export const InformationGrid: FC<InformationGridProps> = ({
    items,
    className = '',
}): ReactElement => (
    <div className={`information-grid ${className}`}>
        {items.map(item => (
            <div key={item.id} className="information-grid__item">
                {item.icon && (
                    <Icon name={item.icon} className="information-grid__icon" />
                )}

                <div className="information-grid__content">
                    <h3 className="information-grid__title">
                        {item.title}
                    </h3>

                    <SafeHtml
                        html={item.html}
                        className="information-grid__html"
                    />
                </div>
            </div>
        ))}
    </div>
);
