import { CSSProperties, FC, ReactElement } from 'react';

import { LinkIconButton, Picture, VideoTheatre } from '../../../compositions';
import { HeaderInterface } from '../../../entities/@blocks/Header/Header';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import useTrans from '../../../hooks/useTrans';

import './Header.scss';

interface HeaderProps extends HeaderInterface {
    showBackButton?: boolean;
    className?: string;
}

export const Header: FC<HeaderProps> = ({
    showBackButton,
    accentColour,
    title,
    subTitle,
    intro,
    image,
    video,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const cssVariables = {
        '--c-accent': accentColour,
    } as CSSProperties;

    return (
        <header style={cssVariables} className={`header ${className}`}>
            <div className="header__wrapper">
                <div className="header__media-wrapper">
                    {!video && image && (
                        <Picture
                            {...image}
                            className="header__picture"
                            imageClassName="header__image"
                        />
                    )}

                    {video && (
                        <VideoTheatre
                            {...video}
                            poster={image}
                            className="header__video-theatre"
                        />
                    )}
                </div>

                <div className="header__text-wrapper">
                    <div className="header__text-inner-wrapper">
                        {showBackButton && (
                            <LinkIconButton
                                to={trans(appRoutes[AppRoute.inspiration].path)}
                                icon="chevron-left"
                                iconPos={HorizontalAlignment.left}
                                text={trans('containers.header.backButton')}
                                className="header__back-button"
                                labelClassName="header__back-button-label"
                                iconClassName="header__back-button-icon"
                            />
                        )}

                        <div className="header__title-wrapper">
                            <h1 className="header__title">
                                {title}
                            </h1>

                            {subTitle && (
                                <h2 className="header__sub-title">
                                    {subTitle}
                                </h2>
                            )}
                        </div>

                        {intro && (
                            <p className="header__intro">
                                {intro}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};
