import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { FaqList } from '../../compositions';
import { FaqData } from '../../entities/Faq/Faq';
import useTrans from '../../hooks/useTrans';

import './Faq.scss';

interface FaqProps extends FaqData {
    showCallToActionToAllFaqs?: boolean;
    className?: string;
}

export const Faq: FC<FaqProps> = ({
    faqItems,
    showCallToActionToAllFaqs,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <article className={`faq ${className}`}>
            <div className="faq__card">
                <FaqList faqItems={faqItems} />

                {showCallToActionToAllFaqs && (
                    <div className="faq__all-faqs">
                        <div className="faq__card-title">
                            {trans('compositions.faq.allFaqCtaTitle')}
                        </div>
                        <p>
                            {trans('compositions.faq.allFaqCtaLinkIntro')}
                            {' '}
                            <Link
                                to="/veelgestelde-vragen"
                                className="faq__card-link"
                            >
                                {trans('compositions.faq.allFaqCtaLinkText')}
                            </Link>
                            .
                        </p>
                    </div>
                )}
            </div>
        </article>
    );
};
