import { FC, ReactElement } from 'react';

import { Price } from '../../components';
import { CartInterface } from '../../entities/Cart/Cart';
import trans from '../../helpers/trans';
import { CartProduct } from '../CartSidebar/subcomponents';

import './Cart.scss';

interface CartProps extends CartInterface {
    onRemoveFromCart: (productId: string) => void;
    removingCartItemId: string | null;
    className?: string;
}

export const Cart: FC<CartProps> = ({
    cartData,
    totalPrice,
    onRemoveFromCart,
    className = '',
}): ReactElement => (
    <div className={`cart ${className}`}>
        <div className="cart__products-wrapper">
            {cartData.map((item) => (
                <CartProduct
                    {...item}
                    key={item.product.id}
                    onRemoveFromCart={onRemoveFromCart}
                />
            ))}
        </div>

        <div className="cart__total-price-wrapper">
            <p className="cart__total">
                {trans('containers.cart.total')}
            </p>

            <Price
                amount={totalPrice}
                className="cart__product-price"
            />
        </div>
    </div>
);
