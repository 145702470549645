import { FC, ReactElement } from 'react';

import { ProductsFilters, ProductsSorting } from '../../compositions';
import {
    InstantSearchPagination,
    InstantSearchProductsCount,
    InstantSearchResult,
    InstantSearchSearchBar,
} from './subcomponents';

import './InstantSearch.scss';

interface InstantSearchProps {
    className?: string;
}

export const InstantSearch: FC<InstantSearchProps> = ({
    className = '',
}): ReactElement => (
    <section className={`instant-search ${className}`}>
        <div className="instant-search__configure-wrapper">
            <ProductsFilters className="instant-search__products-filters" />
            <InstantSearchProductsCount className="instant-search__products-count" />
            <ProductsSorting className="instant-search__products-sorting" />
        </div>

        <InstantSearchSearchBar className="instant-search__search-bar" />

        <InstantSearchResult />

        <InstantSearchPagination className="instant-search__pagination" />
    </section>
);
