import { FC, ReactElement } from 'react';

import { BrandHeader } from '../../compositions/BrandHeader/BrandHeader';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { CarouselInterface } from '../../entities/@blocks/Carousel/Carousel';
import { ContentColumnsInterface } from '../../entities/@blocks/ContentColumns/ContentColumns';
import { FeaturedProductsInterface } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { HeaderInterface } from '../../entities/@blocks/Header/Header';
import { MediaGridInterface } from '../../entities/@blocks/MediaGrid/MediaGrid';
import { ContentBlock, PageSectionInterface } from '../../entities/ContentBlocks/ContentBlocks';
import { Carousel } from '../@blocks/Carousel/Carousel';
import { ContentColumns } from '../@blocks/ContentColumns/ContentColumns';
import { FeaturedProducts } from '../@blocks/FeaturedProducts/FeaturedProducts';
import { Header } from '../@blocks/Header/Header';
import { MediaGrid } from '../@blocks/MediaGrid/MediaGrid';

import './ContentBlocksBrandAndArticleRenderer.scss';

interface BrandAllowedContentBlocksProps {
    contentBlocksData: PageSectionInterface[];
    className?: string;
}

export const ContentBlocksBrandAndArticleRenderer: FC<BrandAllowedContentBlocksProps> = ({
    className = '',
    contentBlocksData,
}): ReactElement => (
    <div className={`content-blocks-brand-and-article-renderer ${className}`}>
        {contentBlocksData.map((contentBlock) => {
            if (contentBlock.type === ContentBlock.header) {
                return (
                    <Header
                        {...contentBlock as HeaderInterface}
                        key={contentBlock.reactKey}
                        className="content-blocks-brand-and-article-renderer__block"
                    />
                );
            }

            if (contentBlock.type === ContentBlock.brandHeader) {
                return (
                    <BrandHeader
                        {...contentBlock as BrandHeaderInterface}
                        key={contentBlock.reactKey}
                        className="content-blocks-brand-and-article-renderer__block"
                    />
                );
            }

            if (contentBlock.type === ContentBlock.carousel) {
                return (
                    <Carousel
                        {...contentBlock as CarouselInterface}
                        key={contentBlock.reactKey}
                        className="content-blocks-brand-and-article-renderer__block"
                    />
                );
            }

            if (contentBlock.type === ContentBlock.featuredProducts) {
                return (
                    <FeaturedProducts
                        {...contentBlock as FeaturedProductsInterface}
                        key={contentBlock.reactKey}
                        className="content-blocks-brand-and-article-renderer__block"
                    />
                );
            }

            if (contentBlock.type === ContentBlock.mediaGrid) {
                return (
                    <MediaGrid
                        {...contentBlock as MediaGridInterface}
                        key={contentBlock.reactKey}
                        className="brand-detail-page__block"
                    />
                );
            }

            if (contentBlock.type === ContentBlock.contentColumns) {
                return (
                    <ContentColumns
                        {...contentBlock as ContentColumnsInterface}
                        key={contentBlock.reactKey}
                        className="brand-detail-page__block"
                    />
                );
            }

            return null;
        })}
    </div>
);
