import { FooterNavigation } from '../../entities/Footer/Footer';
import { LinkTarget } from '../../entities/Link/Link';

export const MOCK_FOOTER_NAVIGATION: FooterNavigation = {
    id: '1',
    list: [
        {
            id: '1',
            order: 1,
            label: 'Klantenservice',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: 'Contact',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: 'Bestellingen & betalen',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: 'Bezorging & levertijden',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '4',
                    order: 4,
                    label: 'Ruilen & retouren',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: 'Winkelvoorwaarden',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: 'Veelgestelde vragen',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '7',
                    order: 7,
                    label: 'Account',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
            ],
        },
        {
            id: '2',
            order: 2,
            label: 'Over Loods 5',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: 'Ons verhaal',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: 'Deelnemen bij Loods 5',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: 'Werken bij Loods 5',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    label: 'Media & PR',
                    href: '',
                    target: LinkTarget.blank,
                    id: '4',
                    order: 4,
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: 'Duurzaamheid',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: 'Inspiratie',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
            ],
        },
        {
            id: '3',
            order: 3,
            label: 'Shoppen bij Loods 5',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: 'Winkels',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '2',
                    order: 2,
                    label: 'Openingstijden',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '3',
                    order: 3,
                    label: 'Contact',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '4',
                    order: 4,
                    label: 'Afdelingen',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '5',
                    order: 5,
                    label: 'Restaurant',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
                {
                    id: '6',
                    order: 6,
                    label: 'Cadeaukaart (saldo)',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                },
            ],
        },
        {
            id: '4',
            order: 4,
            label: 'Social media',
            href: '',
            target: LinkTarget.blank,
            subNavigation: [
                {
                    id: '1',
                    order: 1,
                    label: 'instagram',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'instagram',
                },
                {
                    id: '2',
                    order: 2,
                    label: 'facebook',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'facebook',
                },
                {
                    id: '3',
                    order: 3,
                    label: 'pinterest',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'pinterest',
                },
                {
                    id: '4',
                    order: 4,
                    label: 'youtube',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'youtube',
                },
                {
                    id: '5',
                    order: 5,
                    label: 'tiktok',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'tiktok',
                },
                {
                    id: '6',
                    order: 6,
                    label: 'linkedin',
                    href: '',
                    target: LinkTarget.blank,
                    subNavigation: [],
                    icon: 'linkedin',
                },
            ],
        },
    ],
};
