import { FC, ReactElement } from 'react';

import { useHits } from 'react-instantsearch';

import { Product } from '../../../../entities/Product/Product';
import { MOCK_PRODUCT_IMAGE_1 } from '../../../../mock/mock-data';
import { MOCK_BRANDS } from '../../../../mock/mock-data/brand';
import { InstantSearchHit } from '../InstantSearchHit/InstantSearchHit';

import './InstantSearchResult.scss';

interface InstantSearchResultProps {
    className?: string;
}

export const InstantSearchResult: FC<InstantSearchResultProps> = ({
    className = '',
}): ReactElement => {
    const { items } = useHits();

    const transformedItems: Product[] = items.map(item => ({
        id: item.id,
        name: item.title,
        images: item.images ?? [MOCK_PRODUCT_IMAGE_1],
        price: item.price ?? 0o0,
        brand: item.brand ?? MOCK_BRANDS[0],
        colors: item.colors ?? [],
        stock: item.stock ?? Math.floor(Math.random() * 3),
        variants: item.variants ?? Math.floor(Math.random() * 5),
        slug: item.slug ?? item.title.toLowerCase().replace(/\s+/g, '-'),
    }));

    return (
        <div className={`instant-search-result ${className}`}>
            {transformedItems.map(item => (
                <InstantSearchHit
                    key={item.id}
                    hit={item}
                />
            ))}
        </div>
    );
};
