/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconConversation: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 32 27" className={`icon ${className}`}>
        <path
            className={accentClassName}
            d="M4.8 14.2L4.25 15.55L3.9 16.45L7.4 15.65L7.9 15.5L8.4 15.65C9.05 15.75 9.7 15.8 10.35 15.8C15.2 15.8 18.35 12.6 18.35 9.4C18.35 6.25 15.2 3 10.35 3C5.55 3 2.4 6.25 2.4 9.4C2.4 10.75 2.9 12 3.85 13.1L4.8 14.2ZM2.85 19.15L0 19.8L1.05 17.1L2.05 14.7C0.75 13.2 0 11.4 0 9.4C0 4.55 4.65 0.6 10.4 0.6C16.1 0.6 20.8 4.55 20.8 9.4C20.8 14.3 16.1 18.2 10.4 18.2C9.55 18.2 8.75 18.15 7.95 18L2.85 19.15ZM12.25 19.7C13.1 19.6 13.95 19.4 14.75 19.1C16.1 20.9 18.5 22.2 21.6 22.2C22.25 22.2 22.9 22.15 23.55 22.05L24.05 21.9L24.55 22.05L28.05 22.85L27.7 21.95L27.15 20.6L28.1 19.5C29.05 18.4 29.6 17.15 29.6 15.8C29.6 12.8 26.75 9.8 22.4 9.45V9.4C22.4 8.6 22.25 7.8 22.05 7.05C27.6 7.25 32 11.1 32 15.8C32 17.8 31.2 19.6 29.9 21.1L30.9 23.5L32 26.2L29.15 25.55L24 24.4C23.2 24.55 22.4 24.6 21.6 24.6C17.5 24.6 13.95 22.6 12.25 19.7ZM7.3 6.85C7.3 5.8 8.2 4.95 9.25 4.95H11.25C12.45 4.95 13.45 5.9 13.45 7.1C13.45 7.9 13.05 8.65 12.35 9L11.2 9.7V10V10.8H9.6V10V9.25V8.75L10 8.55L11.55 7.65C11.75 7.55 11.85 7.35 11.85 7.1C11.85 6.8 11.6 6.55 11.25 6.55H9.25C9.05 6.55 8.9 6.7 8.9 6.85V7.1H7.3V6.85ZM9.55 12.25H11.25V13.95H9.55V12.25Z"
        />
    </svg>
));
