import { FC, ReactElement, useRef } from 'react';

import { Icon, Price } from '../../components';
import {
    Dialog,
    DialogContent,
    LinkButton,
    ShoppingCartCount,
} from '../../compositions';
import { CartSidebarInterface } from '../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import trans from '../../helpers/trans';
import { CartProduct } from './subcomponents';

import './CartSidebar.scss';

interface CartSidebarProps extends CartSidebarInterface {
    removingCartItemId: string | null;
    onRemoveFromCart: (productId: string) => void;
    className?: string;
}

export const CartSidebar: FC<CartSidebarProps> = ({
    cartData,
    totalPrice,
    uspItems,
    onRemoveFromCart,
    className = '',
}): ReactElement => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const totalItemsInCart = cartData.reduce((acc, item) => acc + item.quantity, 0);

    const handleOpenDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.showModal();
    };

    const handleCloseDialog = async (): Promise<void> => {
        if (!dialogRef.current) {
            return;
        }

        dialogRef.current.close();
    };

    // TODO: Activate "remove from cart" transition when removing product from cart

    return (
        <div className={`cart-sidebar ${className}`}>
            <ShoppingCartCount
                count={totalItemsInCart}
                onClick={handleOpenDialog}
            />

            <Dialog
                ref={dialogRef}
                onClose={handleCloseDialog}
                enableBackdropClose
                hasDarkBackdrop
                className="cart-sidebar__dialog"
            >
                <DialogContent>
                    <p className="cart-sidebar__heading">
                        {trans('common.cart')}
                    </p>

                    <div className="cart-sidebar__items-wrapper">
                        {cartData.map((item) => (
                            <CartProduct
                                {...item}
                                key={item.product.id}
                                onRemoveFromCart={onRemoveFromCart}
                            />
                        ))}
                    </div>

                    <ul className="cart-sidebar__usp-list">
                        {uspItems.map((item) => (
                            <li
                                key={item.id}
                                className="cart-sidebar__usp-item"
                            >
                                <Icon
                                    name={item.icon}
                                    className="cart-sidebar__usp-item-icon"
                                />

                                {item.text}
                            </li>
                        ))}
                    </ul>

                    <div className="cart-sidebar__total-price-wrapper">
                        <p className="cart-sidebar__total">
                            {trans('containers.cart.total')}
                        </p>

                        <Price
                            amount={totalPrice}
                            className="cart-sidebar__total-price"
                        />
                    </div>

                    <LinkButton
                        text={trans('common.toCart')}
                        to={trans(appRoutes[AppRoute.cart].path)}
                        isSmall
                        className="cart-sidebar__to-cart-button"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};
