import {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Icon, RootPortal } from '../../components';
import { WayfindingType } from '../../entities/Wayfinding/Wayfinding';
import { transformToWayfindingIcon } from '../../entities/Wayfinding/WayfindingTransformers';
import usePointerPosition from '../../hooks/usePointerPosition';

import './WayfindingWrapper.scss';

export interface WayfindingWrapperProps {
    isDisabled?: boolean;
    id: string;
    type: WayfindingType;
    className?: string;
    iconPathClassName?: string;
}

export const WayfindingWrapper: FC<PropsWithChildren<WayfindingWrapperProps>> = ({
    isDisabled,
    id,
    type,
    className = '',
    iconPathClassName = '',
    children,
}): ReactElement => {
    const wayfindingWrapperRef = useRef<HTMLDivElement>(null);
    const pointerPosition = usePointerPosition();

    const [isRevealed, setIsRevealed] = useState<boolean>(false);

    const handleRevealIcon = (): void => {
        if (!isDisabled) {
            setIsRevealed(true);
        }
    };

    const handleHideIcon = (): void => setIsRevealed(false);

    const icon = useMemo(() => (
        transformToWayfindingIcon(type)
    ), [type]);

    const wayfindingWrapperClassNames = classNames('wayfinding-wrapper', {
        'wayfinding-wrapper--is-disabled': isDisabled,
    }, className);

    const iconWrapperClassNames = classNames('wayfinding-wrapper__icon-wrapper', {
        'wayfinding-wrapper__icon-wrapper--is-revealed': isRevealed,
        'wayfinding-wrapper__icon-wrapper--is-disabled': isDisabled,
    });

    const cssVariables = useMemo(() => ({
        '--wayfinding-icon-x': `${pointerPosition.x}px`,
        '--wayfinding-icon-y': `${pointerPosition.y}px`,
    } as CSSProperties), [pointerPosition]);

    return (
        <div
            ref={wayfindingWrapperRef}
            aria-describedby={id}
            onPointerEnter={handleRevealIcon}
            onPointerLeave={handleHideIcon}
            className={wayfindingWrapperClassNames}
        >
            <RootPortal>
                <div
                    id={id}
                    role="tooltip"
                    style={cssVariables}
                    className={iconWrapperClassNames}
                >
                    <Icon
                        name={icon}
                        className="wayfinding-wrapper__icon"
                        pathClassName={`wayfinding-wrapper__icon-path ${iconPathClassName}`}
                    />
                </div>
            </RootPortal>

            <div className="wayfinding-wrapper__child-wrapper">
                {children}
            </div>
        </div>
    );
};
