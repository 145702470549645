import {
    CSSProperties,
    FC,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import { Dialog, IconButton, ProductCard } from '../../../../../compositions';
import { InteractiveProductHeaderItem } from '../../../../../entities/@blocks/InteractiveProductHeader/InteractiveProductHeader';
import { AnchorPosition } from '../../../../../entities/Alignment/Alignment';
import useDeviceWidth from '../../../../../hooks/useDeviceWidth';
import useHandleClickOutside from '../../../../../hooks/useHandleClickOutside';
import useTrans from '../../../../../hooks/useTrans';

import './ProductPopupItem.scss';

interface ProductPopupItemProps extends InteractiveProductHeaderItem {
    className?: string;
}

export const ProductPopupItem: FC<ProductPopupItemProps> = ({
    product,
    position,
    mobilePosition,
    anchorPosition = AnchorPosition.blockEndInlineEnd,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [shouldHide, setShouldHide] = useState<boolean>(false);

    const itemRef = useRef<HTMLDivElement>(null);
    const dialogRef = useRef<HTMLDialogElement>(null);

    useHandleClickOutside(itemRef, (): void => {
        if (isOpen) {
            setShouldHide(true);
        }
    });

    const handleToggleItem = useCallback((): void => {
        if (!dialogRef.current) {
            return;
        }

        if (isOpen) {
            setShouldHide(true);
            return;
        }

        if (isMobile) {
            dialogRef.current.showModal();
        } else {
            dialogRef.current.show();
        }

        setIsOpen(true);
    }, [isMobile, isOpen]);

    const handleCloseItem = (): void => {
        if (dialogRef.current) {
            dialogRef.current.close();

            setIsOpen(false);
            setShouldHide(false);
        }
    };

    const cssVariables = {
        '--product-position-x': `${position.x}%`,
        '--product-position-y': `${position.y}%`,
        '--product-mobile-position-x': `${mobilePosition?.x || position.x}%`,
        '--product-mobile-position-y': `${mobilePosition?.y || position.y}%`,
    } as CSSProperties;

    const productPopupItemClassNames = classNames('product-popup-item', {
        'product-popup-item--is-open': isOpen,
    }, className);

    const dialogClassNames = classNames('product-popup-item__dialog', {
        'product-popup-item__dialog--inset-block-start': anchorPosition.startsWith('block-start-'),
        'product-popup-item__dialog--inset-block-end': anchorPosition.startsWith('block-end-'),
        'product-popup-item__dialog--inset-inline-start': anchorPosition.endsWith('-inline-start'),
        'product-popup-item__dialog--inset-inline-end': anchorPosition.endsWith('-inline-end'),
    });

    return (
        <div
            ref={itemRef}
            style={cssVariables}
            className={productPopupItemClassNames}
        >
            <IconButton
                hideLabel
                icon={isOpen ? 'minus' : 'plus'}
                text={trans('containers.interactiveProductHeader.showItem')}
                onClick={handleToggleItem}
                className="product-popup-item__button"
                labelClassName="product-popup-item__button-label"
            />

            <Dialog
                ref={dialogRef}
                shouldHide={shouldHide}
                enableBackdropClose
                hasLightBackdrop
                onClose={handleCloseItem}
                className={dialogClassNames}
                closeButtonClassName="product-popup-item__dialog-close-button"
            >
                <div className="product-popup-item__product-card-wrapper">
                    <ProductCard product={product} className="product-popup-item__product-card" />
                </div>
            </Dialog>
        </div>
    );
};
