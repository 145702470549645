import { SearchableOption } from '../Form/Form';
import { MapLocation, OpenStreetMapSearchResult, splitCharacter } from './Map';

export const transformLocationsToSearchableOptions = (data: OpenStreetMapSearchResult): SearchableOption[] => data.features.map((feature) => ({
    label: feature.properties.display_name
        .split(', Nederland').join(''),
    value: `${feature.geometry.coordinates[1]}${splitCharacter}${feature.geometry.coordinates[0]}`,
}));

export const transformSearchableOptionToMapLocation = (value: SearchableOption): MapLocation => ({
    label: value.label,
    location: {
        latitude: parseFloat(value.value.split(splitCharacter)[0].trim()),
        longitude: parseFloat(value.value.split(splitCharacter)[1].trim()),
    },
});
