import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Price } from '../../components';
import { Product } from '../../entities/Product/Product';
import useTrans from '../../hooks/useTrans';
import { Picture } from '..';
import { Button } from '../@buttons/Button/Button';
import { FavouriteButton } from '../@buttons/FavouriteButton/FavouriteButton';

import './ProductCard.scss';

interface ProductCardProps {
    product: Product;
    className?: string;
    imageClassname?: string;
    pictureClassname?: string;
}

export const ProductCard: FC<ProductCardProps> = ({
    product,
    className = '',
    imageClassname = '',
    pictureClassname = '',
}): ReactElement => {
    const trans = useTrans();
    const [isFavourite, toggleIsFavourite] = useToggle(false);
    const visibleColors = product.colors.slice(0, 5);

    const pictureClassNames = classNames('product-card__image-default', {
        'product-card__image-default--sold-out': product.images.length === 1 && product.stock === 0,
        'product-card__image-switch-primary': product.images.length > 1,
        'product-card__image-switch-primary--sold-out': product.images.length > 1 && product.stock === 0,
    }, pictureClassname);

    const secondaryPictureClassNames = classNames('product-card__image-switch-secondary', {
        'product-card__image-switch-secondary--sold-out': product.stock === 0,
    }, pictureClassname);

    return (
        <div className={`product-card ${className}`}>
            <div className="product-card__image-wrapper">
                {product.stock === 0 && (
                    <p className="product-card__sold-out-label">
                        {trans('compositions.productCard.soldOut')}
                    </p>
                )}

                <Link to={`/product/${product.slug}`} className="product-card__image-wrapper">
                    <Picture
                        {...product.images[0]}
                        className={pictureClassNames}
                        imageClassName={imageClassname}
                    />

                    {product.images.length > 1 && (
                        <Picture
                            {...product.images[1]}
                            className={secondaryPictureClassNames}
                            imageClassName={imageClassname}
                        />
                    )}
                </Link>

                <div className="product-card__buttons">
                    <Button
                        hasAnimation
                        text={trans('compositions.productCard.add')}
                        className="product-card__add-to-cart-button"
                    />

                    <FavouriteButton
                        isActive={isFavourite}
                        text={trans('common.addToFavourites')}
                        onClick={toggleIsFavourite}
                        className="product-card__add-to-favourite-button"
                    />
                </div>
            </div>

            <div className="product-card__info">
                <Link to={`/merk/${product.brand.slug}`} className="product-card__brand-link">
                    <h3 className="product-card__brand">
                        {product.brand.name}
                    </h3>
                </Link>

                <Link to={`/product/${product.slug}`} className="product-card__product-link">
                    <h2 className="product-card__name">
                        {product.name}
                    </h2>
                </Link>

                <Price amount={product.price} className="product-card__price" />

                {product.variants === 1 && product.colors.length > 1 && (
                    <div className="product-card__color-swatch">
                        <ul className="product-card__colors-list">
                            {visibleColors.map((color, index) => (
                                <li
                                    key={color[index]}
                                    className="product-card__colors-item"
                                    style={{ backgroundColor: color }}
                                />
                            ))}
                        </ul>

                        {product.colors.length > 5 && (
                            <p className="product-card__colors-amount">
                                +
                                {product.colors.length - 5}
                            </p>
                        )}
                    </div>
                )}

                {product.variants > 1 && (
                    <p className="product-card__variants">
                        <span>
                            {trans('compositions.productCard.availableIn')}
                        </span>
                        {' '}
                        <span className="product-card__variants-amount">
                            {trans('compositions.productCard.variants', { amount: product.variants })}
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
};
