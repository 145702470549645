import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Article } from '../../entities/Article/Article';
import { WayfindingType } from '../../entities/Wayfinding/Wayfinding';
import useFormatDate from '../../hooks/useFormatDate';
import useTrans from '../../hooks/useTrans';
import { LinkIconButton, Picture, WayfindingWrapper } from '..';

import './ArticleCard.scss';

export interface ArticleCardProps {
    article: Article;
    className?: string;
}

export const ArticleCard: FC<ArticleCardProps> = ({
    article,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const formattedPublishedDate = formatDate(article.publishDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    const articleLink = `/blog/${article.slug}`;

    return (
        <div className={`article-card ${className}`}>
            <WayfindingWrapper
                id={`${article.id}-card`}
                type={WayfindingType.enter}
                className="article-card__wayfinding-wrapper"
                iconPathClassName="article-card__wayfinding-icon-path"
            >
                <Link to={articleLink} tabIndex={-1}>
                    <div className="article-card__image-wrapper">
                        <Picture {...article.image} className="article-card__image" />
                    </div>
                </Link>
            </WayfindingWrapper>

            <div className="article-card__text-wrapper">
                <p className="article-card__details-wrapper">
                    <span className="article-card__tag">
                        {article.tag.name}
                    </span>

                    <span className="article-card__published-date">
                        {formattedPublishedDate}
                    </span>
                </p>

                <h3 className="article-card__title">
                    {article.title}
                </h3>

                <LinkIconButton
                    to={articleLink}
                    iconPos={HorizontalAlignment.right}
                    icon="arrow-short-right"
                    text={trans('compositions.articleCard.readMore')}
                    className="article-card__link-button"
                    labelClassName="article-card__link-label"
                    iconClassName="article-card__link-icon"
                />
            </div>
        </div>
    );
};
