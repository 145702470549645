import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../../components';
import { LinkIconButton, Picture } from '../../../compositions';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { Article } from '../../../entities/Article/Article';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import useFormatDate from '../../../hooks/useFormatDate';
import useTrans from '../../../hooks/useTrans';

import './HighlightedArticle.scss';

interface HighlightedArticleProps {
    article: Article;
    imagePosition?: HorizontalAlignment;
    className?: string;
}

export const HighlightedArticle: FC<HighlightedArticleProps> = ({
    article,
    imagePosition = HorizontalAlignment.left,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const formattedPublishedDate = formatDate(article.publishDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    const articleDetailPath = trans(appRoutes[AppRoute.articleDetail].path);
    const articleDetailUrl = replaceUrlParamKeysWithValues(articleDetailPath, { slug: article.slug });

    const imageTextBlockWrapperClassNames = classNames('highlighted-article__wrapper', {
        [`highlighted-article__wrapper--image-${imagePosition}`]: imagePosition,
    });

    return (
        <div className={`highlighted-article ${className}`}>
            <Wrapper className="highlighted-article__wrapper">
                <div className={imageTextBlockWrapperClassNames}>
                    <div className="highlighted-article__text-wrapper">
                        {article.tag && article.publishDate && (
                            <p className="highlighted-article__meta-wrapper">
                                <span className="highlighted-article__tag">
                                    {article.tag.name}
                                </span>

                                <span className="highlighted-article__publish-date">
                                    {formattedPublishedDate}
                                </span>
                            </p>
                        )}

                        <div className="highlighted-article__title-wrapper">
                            <h2 className="highlighted-article__title">
                                {article.title}
                            </h2>
                        </div>

                        <Picture
                            {...article.image}
                            className="highlighted-article__mobile-picture"
                            imageClassName="highlighted-article__mobile-image"
                        />

                        {article.intro && (
                            <p className="highlighted-article__description">
                                {article.intro}
                            </p>
                        )}

                        <LinkIconButton
                            to={articleDetailUrl}
                            icon="arrow-short-right"
                            iconPos={HorizontalAlignment.right}
                            text={trans('common.readMore')}
                            className="highlighted-article__button"
                        />
                    </div>

                    <div className="highlighted-article__image-wrapper">
                        <Picture
                            {...article.image}
                            className="highlighted-article__picture"
                            imageClassName="highlighted-article__image"
                        />
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};
