import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../../components';
import { ContentColumnsInterface } from '../../../entities/@blocks/ContentColumns/ContentColumns';
import { ContentColumn } from './subcomponents';

import './ContentColumns.scss';

interface ContentColumnsProps extends ContentColumnsInterface {
    className?: string;
}

export const ContentColumns: FC<ContentColumnsProps> = ({
    columns,
    className = '',
}): ReactElement => (
    <div className={`content-columns ${className}`}>
        <Wrapper className="content-columns__wrapper">
            {columns.map(column => {
                const { html, image, video } = column.content;

                const hasQuote = html.includes('<q') || html.includes('<blockquote');
                const hasMedia = !!image || !!video;

                const columnClassNames = classNames('content-columns__column', {
                    'content-columns__column--has-border': !hasQuote && !hasMedia,
                });

                return (
                    <ContentColumn
                        {...column.content}
                        key={column.id}
                        className={columnClassNames}
                    />
                );
            })}
        </Wrapper>
    </div>
);
