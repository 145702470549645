import { FC } from 'react';

import { ProductMedia, ProductSidebar } from '../../../compositions';
import { Product } from '../../../entities/Product/Product';

import './ProductDetail.scss';

interface ProductDetailProps {
    product: Product;
    className?: string;
}

export const ProductDetail: FC<ProductDetailProps> = ({
    product,
    className = '',
}) => (
    <section className={`product-detail ${className}`}>
        <div className="product-detail__main">
            <ProductMedia
                product={product}
                className="product-detail__media"
            />
        </div>
        <ProductSidebar
            product={product}
            className="product-detail__sidebar"
        />
    </section>
);
