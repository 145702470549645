import { FC, ReactElement } from 'react';

import { useSearchBox } from 'react-instantsearch';

import { TextInput } from '../../../../compositions';
import useTrans from '../../../../hooks/useTrans';

interface InstantSearchSearchBarProps {
    className?: string;
}

export const InstantSearchSearchBar: FC<InstantSearchSearchBarProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { query, refine } = useSearchBox();

    const handleChange = (value: string): void => {
        refine(value);
    };

    return (
        <TextInput
            type="search"
            icon="search"
            placeholder={trans('common.search')}
            label={trans('common.search')}
            hideLabel
            value={query}
            onChange={handleChange}
            className={`instant-search-search-bar ${className}`}
        />
    );
};
