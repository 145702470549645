import { SearchableOption } from '../../entities/Form/Form';
import { OpenStreetMapSearchResult } from '../../entities/Map/Map';
import { transformLocationsToSearchableOptions } from '../../entities/Map/MapTransformer';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setLocations } from './mapLocationReducers';

export const getMapLocationBySearchTerm = (searchTerm: string, limit: number = 5) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    const params = new URLSearchParams({
        q: searchTerm,
        format: 'geojson',
        layers: 'address',
        limit: limit.toString(),
        countrycodes: 'nl',
        'accept-language': 'nl',
    });

    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?${params}`);

        if (!response.ok) {
            throw new Error('Failed to fetch locations');
        }

        const data: OpenStreetMapSearchResult = await response.json();

        const results: SearchableOption[] = transformLocationsToSearchableOptions(data);

        const uniqueResults = Array.from(new Set(results));

        dispatch(setLocations(uniqueResults));
    } catch (error) {
        const errorMessage = (error as Error).message || 'Failed to fetch map locations';
        dispatch(setError(errorMessage));
    } finally {
        dispatch(setIsLoading(false));
    }
};
