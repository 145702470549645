import { FC, ReactElement, useState } from 'react';

import { useDebounce } from 'react-use';

import { ShopLocator } from '../../containers';
import { SearchableOption } from '../../entities/Form/Form';
import { MapLocation } from '../../entities/Map/Map';
import { isSSR } from '../../helpers';
import { MOCK_SHOP_LOCATIONS } from '../../mock/mock-data';
import { getMapLocationBySearchTerm } from '../../redux/mapLocation/mapLocationActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedShopLocator: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const searchResults = useTypedSelector((state) => state.mapLocationSlice.locations);

    const [queryOption, setQueryOption] = useState<SearchableOption>();

    useDebounce((): void => {
        if (queryOption) {
            dispatch(getMapLocationBySearchTerm(queryOption.label));
        }
    }, 500, [queryOption]);

    const handleMarkerClick = (location: MapLocation): void => {
        if (!isSSR) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(location.label)}`);
        }
    };

    return (
        <ShopLocator
            shopLocations={MOCK_SHOP_LOCATIONS}
            searchResults={searchResults}
            onQueryChange={setQueryOption}
            onMarkerClick={handleMarkerClick}
        />
    );
};
