import { FC, ReactElement } from 'react';

import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import useTrans from '../../hooks/useTrans';
import { IconButton } from '..';

import './ProductsFilters.scss';

interface ProductsFiltersProps {
    className?: string;
}
export const ProductsFilters: FC<ProductsFiltersProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`products-filters ${className}`}>
            <IconButton
                isSmall
                icon="filter"
                text={trans('compositions.productsFilters.allFilters')}
                iconPos={HorizontalAlignment.right}
                className="product-filters__button"
            />
        </div>
    );
};
