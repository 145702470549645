import { OpenStatus, WorkingHours } from '../entities/ServiceContactDetails/ServiceContactDetails';

export const getOpenStatus = (workingHours: (WorkingHours | null)[] | undefined): OpenStatus => {
    // No working hours > Open 24/7
    if (!workingHours?.length) {
        return {
            isOpen: true,
            openUntil: '24/7',
        };
    }

    const now = new Date();
    const todayWorkingHours = workingHours[now.getDay()] ?? null;

    const closed: OpenStatus = {
        isOpen: false,
        openUntil: todayWorkingHours?.to ?? null,
    };

    // No working hours for this day > Closed / offline
    if (!todayWorkingHours?.from || !todayWorkingHours?.to) {
        return closed;
    }

    // Check if the current time is within the working hours
    const [startHour, startMinute] = todayWorkingHours.from.split(':').map(Number);
    const [endHour, endMinute] = todayWorkingHours.to.split(':').map(Number);

    const start = new Date();
    start.setHours(startHour, startMinute, 0, 0);
    if (now < start) {
        return closed;
    }

    const end = new Date();
    end.setHours(endHour, endMinute, 0, 0);

    if (now > end) {
        return closed;
    }

    return {
        isOpen: true,
        openUntil: todayWorkingHours.to,
    };
};
