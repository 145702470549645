import { FC } from 'react';

import { ContactOption } from '../../components/ContactOption/ContactOption';
import { ServiceContactDetails } from '../../entities/ServiceContactDetails/ServiceContactDetails';
import { formatPhoneNumber } from '../../helpers/phoneNumber';
import useTrans from '../../hooks/useTrans';
import { Input } from '../@inputs/Input/Input';

import './ServiceSection.scss';

interface ServiceSectionProps {
    serviceContactDetails: ServiceContactDetails;
    className?: string;
}

export const ServiceSection: FC<ServiceSectionProps> = ({
    serviceContactDetails,
    className = '',
}) => {
    const trans = useTrans();

    return (
        <section className={`service-section ${className}`}>
            <div className="service-section__column">
                <h2 className="service-section__title">
                    {trans('compositions.serviceContact.title')}
                </h2>
                {/* @TODO: implement in L5W-109 */}
                <Input
                    hasUnderline
                    type="search"
                    icon="search"
                    placeholder={trans('compositions.serviceContact.searchPlaceholder')}
                    className="service-section__search"
                />
            </div>
            <div className="service-section__column">
                <ContactOption
                    linkText={trans('compositions.serviceContact.contactOptionWhatsApp')}
                    linkUrl={`https://wa.me/${formatPhoneNumber(serviceContactDetails.whatsApp.phoneNumber, true)}`}
                    workingHours={serviceContactDetails.whatsApp.workingHours}
                />
            </div>
            <div className="service-section__column">
                <ContactOption
                    linkText={
                        trans(
                            'compositions.serviceContact.contactOptionPhone',
                            { phoneNumber: formatPhoneNumber(serviceContactDetails.phone.phoneNumber, false, true) },
                        )
                    }
                    linkUrl={`tel:${formatPhoneNumber(serviceContactDetails.phone.phoneNumber, false)}`}
                    workingHours={serviceContactDetails.phone.workingHours}
                />
            </div>
            <div className="service-section__column">
                <ContactOption
                    linkText={trans('compositions.serviceContact.contactOptionEmail')}
                    linkUrl={`mailto:${serviceContactDetails.email}`}
                    workingHours={serviceContactDetails.email.workingHours}
                />
            </div>
        </section>
    );
};
