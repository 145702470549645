import {
    DialogHTMLAttributes,
    forwardRef,
    ForwardRefExoticComponent,
    PointerEvent,
    ReactElement,
    Ref,
    RefAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';

import useCombinedRefs from '../../hooks/useCombinedRefs';
import useTrans from '../../hooks/useTrans';
import { IconButton } from '..';

import './Dialog.scss';

interface DialogProps extends DialogHTMLAttributes<HTMLDialogElement>, RefAttributes<HTMLDialogElement> {
    shouldHide?: boolean;
    enableBackdropClose?: boolean;
    hasLightBackdrop?: boolean;
    hasDarkBackdrop?: boolean;
    onClose: () => void;
    className?: string;
    closeButtonClassName?: string;
}

export const Dialog: ForwardRefExoticComponent<DialogProps> = forwardRef(({
    shouldHide,
    enableBackdropClose,
    hasLightBackdrop,
    hasDarkBackdrop,
    onClose,
    className = '',
    closeButtonClassName = '',
    children,
    ...dialogProps
}, ref: Ref<HTMLDialogElement>): ReactElement => {
    const trans = useTrans();
    const [isHidden, setIsHidden] = useState<boolean>(false);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const combinedRef = useCombinedRefs<HTMLDialogElement>(dialogRef, ref);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const hideDialog = (): void => setIsHidden(true);

    useEffect((): void => {
        if (shouldHide) {
            setIsHidden(true);
        }
    }, [shouldHide]);

    const handleDialogClick = (event: PointerEvent<HTMLDialogElement>): void => {
        event.preventDefault();

        if (!combinedRef.current || !enableBackdropClose) {
            return;
        }

        const { clientX, clientY } = event;
        const { top, left } = combinedRef.current.getBoundingClientRect();
        const { width, height } = combinedRef.current.getBoundingClientRect();

        const usedKeyboard = !clientX && !clientY;
        const clickedDialog = top <= clientY
            && clientY <= top + height
            && left <= clientX
            && clientX <= left + width;

        if (!clickedDialog && !usedKeyboard) {
            hideDialog();
        }
    };

    const handleAnimationEnd = (): void => {
        if (!isHidden) {
            return;
        }

        onClose();
        setIsHidden(false);
    };

    const dialogClassNames = classNames('dialog', {
        'dialog--is-hidden': isHidden,
        'dialog--has-interactive-backdrop': enableBackdropClose,
        'dialog--has-light-backdrop': hasLightBackdrop,
        'dialog--has-dark-backdrop': hasDarkBackdrop,
    }, className);

    return (
        // eslint-disable-next-line
        <dialog
            {...dialogProps}
            ref={combinedRef}
            onClick={handleDialogClick}
            onAnimationEnd={handleAnimationEnd}
            className={dialogClassNames}
        >
            {children}

            <IconButton
                ref={closeButtonRef}
                icon="cross"
                text={trans('common.close')}
                hideLabel
                onClick={hideDialog}
                className={`dialog__close-button ${closeButtonClassName}`}
                iconClassName="dialog__close-icon"
            />
        </dialog>
    );
});
