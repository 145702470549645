import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { Input, InputLabelProps, InputLabelWrapper } from '../..';
import { InputProps } from '../Input/Input';

import './TextInput.scss';

export interface TextInputProps extends InputLabelProps, Omit<InputProps, 'onChange'> {
    onChange: (value: string) => void;
    fieldClassName?: string;
}

export const TextInput: ForwardRefExoticComponent<TextInputProps> = forwardRef(({
    label,
    hideLabel,
    type = 'text',
    required,
    tooltip,
    error,
    onChange,
    className = '',
    fieldClassName = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label aria-label={label} className={`text-input ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
            >
                <Input
                    {...inputProps}
                    ref={ref}
                    type={type}
                    required={required}
                    error={error}
                    onChange={handleChange}
                    className={fieldClassName}
                />
            </InputLabelWrapper>
        </label>
    );
});
