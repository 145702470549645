import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Icon } from '../../../../../components';
import { TopNabvarUspType, TopNavbarRating, TopNavbarUsp as TopNavbarUspInterface } from '../../../../../entities/@blocks/TopNavbar/TopNavbar';
import { generateIdArray } from '../../../../../helpers/array';
import useTrans from '../../../../../hooks/useTrans';

import './TopNavbarUsp.scss';

interface TopNavbarUspProps extends Omit<TopNavbarUspInterface, 'id'> {
    className?: string;
}

export const TopNavbarUsp: FC<TopNavbarUspProps> = ({
    type,
    data,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (type === TopNabvarUspType.rating) {
        const ratingData = data as TopNavbarRating;
        const stars = generateIdArray(5).map(star => star + 1);

        return (
            <div className={`top-navbar-usp ${className}`}>
                <Icon
                    name="check"
                    className="top-navbar-usp__check"
                />

                <Link
                    to={ratingData.url}
                    target="_blank"
                    className="top-navbar-usp__link"
                >
                    <p className="top-navbar-usp__label">
                        {trans('containers.topNavbar.rating')}
                    </p>
                </Link>

                <ul className="top-navbar-usp__star-list">
                    {stars.map(star => {
                        const starPathClassNames = classNames('top-navbar-usp__star-path', {
                            'top-navbar-usp__star-path--is-filled': star <= ratingData.stars,
                        });

                        return (
                            <li key={star} className="top-navbar-usp__star-item">
                                <Icon
                                    name="star"
                                    className="top-navbar-usp__star"
                                    pathClassName={starPathClassNames}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    return (
        <div className={`top-navbar-usp ${className}`}>
            <Icon
                name="check"
                className="top-navbar-usp__check"
            />

            <p className="top-navbar-usp__label">
                {data as string}
            </p>
        </div>
    );
};
