import { FC, ReactElement } from 'react';

import { ArticleCard, ProductCard } from '../../../../compositions';
import { SliderSection } from '../../../../containers';
import { MOCK_ARTICLES, MOCK_PRODUCTS } from '../../../../mock/mock-data';

import './SliderExamples.scss';

interface SliderExamplesProps {
    className?: string;
}

export const SliderExamples: FC<SliderExamplesProps> = ({
    className = '',
}): ReactElement => {
    const articles = [...MOCK_ARTICLES, ...MOCK_ARTICLES, ...MOCK_ARTICLES];
    const products = [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS];

    return (
        <div className={`slider-examples ${className}`}>
            <SliderSection
                id="articles-example"
                title="Nieuw bij Loods 5"
                items={articles.map(article => (
                    <ArticleCard article={article} />
                ))}
                link={{
                    href: '/',
                    label: 'Bekijk meer',
                }}
                className="slider-examples__slider"
            />

            <SliderSection
                id="products-example"
                title="Nieuw bij Loods 5"
                items={products.map(product => (
                    <ProductCard
                        product={product}
                        className="slider-examples__slider-product"
                    />
                ))}
                className="slider-examples__slider"
            />
        </div>
    );
};
