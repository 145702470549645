export enum HorizontalAlignment {
    left = 'left',
    right = 'right',
}

export enum AnchorPosition {
    blockStartInlineStart = 'block-start-inline-start',
    blockStartInlineEnd = 'block-start-inline-end',
    blockEndInlineStart = 'block-end-inline-start',
    blockEndInlineEnd = 'block-end-inline-end',
}
