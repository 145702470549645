import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { FeaturedProductsInterface, FeaturedProductsType } from '../../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { FeaturedProductsAlternatingSizes, FeaturedProductsVerticalTitle } from './subcomponents';

import './FeaturedProducts.scss';

export interface FeaturedProductsProps extends FeaturedProductsInterface {
    className?: string;
}

export const FeaturedProducts: FC<FeaturedProductsProps> = ({
    layoutType,
    className = '',
    ...featuredProductsProps
}): ReactElement => (
    <div className={`featured-products ${className}`}>
        <Wrapper className="featured-products__wrapper">
            {layoutType === FeaturedProductsType.alternatingSizes && (
                <FeaturedProductsAlternatingSizes {...featuredProductsProps} />
            )}

            {layoutType === FeaturedProductsType.verticalTitle && (
                <FeaturedProductsVerticalTitle {...featuredProductsProps} />
            )}
        </Wrapper>
    </div>
);
