import {
    defaultLocale,
    Language,
    Locale,
    LocaleData,
    locales,
} from '../entities/Locale/Locale';
import nlLanguage from '../translation/nl.json';
import { isSSR, localStorageExists } from '.';

type TranslationParams = Record<string, string | number | undefined>;
export type TranslatorFunction = (path: string, params?: TranslationParams) => string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-const
let languages: Record<Language, any> = {
    [Language.nl]: nlLanguage,
};

export const getLocaleFromLanguageString = (languageString: string): LocaleData | undefined => (
    Object.values(locales).find(locale => locale.language === languageString)
);

export const getLocaleFromBrowser = (): LocaleData | undefined => {
    if (isSSR) {
        return undefined;
    }

    const { language } = navigator;

    if (language.startsWith('nl')) {
        return locales[Locale.dutch];
    }

    return defaultLocale;
};

export const getTranslation = (language = defaultLocale.language, path = '', params: TranslationParams = {}): string => {
    const selectors = path.split('.');
    const paramKeys = Object.keys(params);

    // Reduce keys to retrieve translation
    const translation = selectors.reduce((currentKey, nextKey) => {
        // Log an error if path can't be resolved, anywhere within translation
        if (!currentKey || !currentKey[nextKey]) {
            console.error(`No translation found for: ${path}`);
            return `{{${path}}}`;
        }

        return currentKey && currentKey[nextKey];
    }, languages[language]);

    if (!params) {
        return translation;
    }

    // Apply given parameters to translation and return outcome
    return paramKeys.reduce((label, param) => {
        const regex = new RegExp(`:${param}:`, 'g');

        return label.replace(regex, params[param]);
    }, translation);
};

export const trans: TranslatorFunction = (...args) => {
    let { language } = defaultLocale;

    if (localStorageExists) {
        const storedLocale = localStorage.getItem('locale');

        if (storedLocale) {
            language = JSON.parse(storedLocale).language;
        }
    }

    return getTranslation(language, ...args);
};

export const getPathFromTranslation = (
    language = defaultLocale.language,
    category = '',
    translation = '',
): string | undefined => {
    const selectors = category.split('.');

    // Reduce keys to retrieve translation
    const translationCategory = selectors.reduce((currentKey, nextKey) => {
        // Log an error if path can't be resolved, anywhere within translation
        if (!currentKey || !currentKey[nextKey]) {
            console.error(`No path found for: ${translation}`);
            return `{{${translation}}}`;
        }

        return currentKey && currentKey[nextKey];
    }, languages[language]);

    let translationKey = '';
    let hasMatch = false;

    // Recursively search through all translations of given translation category/group
    const recursivelyFindKeyByPath = (targetPath: string, currentCategory = translationCategory, currentPath = ''): string => {
        if (hasMatch) {
            return translationKey;
        }

        const keys = Object.keys(currentCategory);

        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const newPath = currentPath ? `${currentPath}.${key}` : key;

            // Break the loop and set a boolean lock once a match is found
            if (currentCategory[key] === targetPath) {
                hasMatch = true;
                translationKey = [category, newPath].join('.');

                break;
            }

            const newPathKeys = newPath.split('.');
            const nextCategory = newPathKeys.reduce((currentKey, nextKey) => (
                currentKey && currentKey[nextKey]
            ), currentCategory);

            // Apply recursion until no next category is found
            if (nextCategory) {
                translationKey = recursivelyFindKeyByPath(targetPath, nextCategory, newPath);
            }
        }

        return translationKey;
    };

    return recursivelyFindKeyByPath(translation, translationCategory);
};

export default trans;
