/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconSmartphone: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 18 27" className={`icon ${className}`}>
        <path
            className={accentClassName}
            d="M2.6 3V23.8H15.4V3H2.6ZM0.2 0.6H2.6H15.4H17.8V3V23.8V26.2H15.4H2.6H0.2V23.8V3V0.6ZM7.4 20.6H10.6H11.4V22.2H10.6H7.4H6.6V20.6H7.4Z"
        />
    </svg>
));
