import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LinkIconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import useTrans from '../../hooks/useTrans';

import './IndexPage.scss';

export const IndexPage: FC = (): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, { slug: 'hay' });

    return (
        <Page className="index-page">
            <Wrapper>
                <section className="index-page__section">
                    <p>Welkom op de homepagina van Loods 5</p>
                </section>

                <section className="index-page__section">
                    <h2>Pagina&apos;s</h2>

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.inspiration].path)}
                        text="Inspiratie overzicht"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.productCategory].path)}
                        text="Producten overzicht"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.homeWithInteractiveProductHeader].path)}
                        text="Homepage met interactieve product header"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.search].path)}
                        text="Producten overzicht - search (meilisearch)"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={brandDetailUrl}
                        text="Merk pagina"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />
                </section>

                <section className="index-page__section">
                    <h2>Overige pagina&apos;s</h2>

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.contentBlocks].path)}
                        text="Content blocks"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.styleguide].path)}
                        text="Styleguide"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />
                </section>
            </Wrapper>
        </Page>
    );
};
