import {
    FC,
    lazy,
    useEffect,
    useState,
} from 'react';

import { IconButton, SearchableValueInput } from '../../compositions';
import { SearchableOption } from '../../entities/Form/Form';
import { MapLocation, ShopMapLocation } from '../../entities/Map/Map';
import { transformSearchableOptionToMapLocation } from '../../entities/Map/MapTransformer';
import trans from '../../helpers/trans';
import useGeolocation from '../../hooks/useGeolocation';

import './ShopLocator.scss';

interface ShopLocatorProps {
    shopLocations: ShopMapLocation[];
    searchResults?: SearchableOption[];
    onQueryChange: (option: SearchableOption) => void;
    onMarkerClick?: (location: MapLocation) => void;
    className?: string;
}

const Map = lazy(() => import('../../compositions/Map/Map')
    .then(module => ({ default: module.Map })));

export const ShopLocator: FC<ShopLocatorProps> = ({
    shopLocations = [],
    searchResults = [],
    onQueryChange,
    onMarkerClick,
    className = '',
}) => {
    const [geolocationState, triggerGeolocation] = useGeolocation();
    const { geolocation, error } = geolocationState;

    const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(null);
    const [showError, setShowError] = useState<string>('');

    const handleSearchTermChange = (value: SearchableOption): void => {
        if (!value) {
            setSelectedLocation(null);
        }

        const transformedLocation = transformSearchableOptionToMapLocation(value);

        setSelectedLocation(transformedLocation);
    };

    const handleGetCurrentLocationClick = () => {
        triggerGeolocation();
    };

    useEffect(() => {
        if (geolocation) {
            setSelectedLocation({
                label: 'Current Location',
                location: {
                    latitude: geolocation.latitude,
                    longitude: geolocation.longitude,
                },
            });
        } else if (error) {
            setShowError(`${error} ${trans('containers.shopLocator.userDeniedCurrentLocation')}`);
        }
    }, [geolocation, error, trans]);

    return (
        <div className={`shop-locator ${className}`}>
            <Map
                center={selectedLocation?.location}
                zoom={selectedLocation ? 10 : 7}
                markers={shopLocations}
                onMarkerClick={onMarkerClick}
                className="shop-locator__map"
            />

            <h2 className="shop-locator__title">
                {trans('containers.shopLocator.visitOurStores')}
            </h2>

            <div className="shop-locator__search-form">
                <div className="shop-locator__search-input-wrapper">
                    <SearchableValueInput
                        isSearchable
                        hideLabel
                        label={trans('containers.shopLocator.searchLabel')}
                        placeholder={trans('containers.shopLocator.inputPlaceholder')}
                        options={searchResults}
                        onOptionClick={handleSearchTermChange}
                        onChange={onQueryChange}
                        className="shop-locator__search-input"
                        fieldClassName="shop-locator__search-input-field"
                        inputWrapperClassName="shop-locator__search-input-wrapper"
                        iconClassname="shop-locator__search-input-icon"
                        iconWrapperClassname="shop-locator__search-input-icon-wrapper"
                    />
                </div>

                <IconButton
                    text={trans('containers.shopLocator.useCurrentLocation')}
                    icon="location"
                    onClick={handleGetCurrentLocationClick}
                    className="shop-locator__current-location-button"
                />

                {showError && (
                    <small className="shop-locator__current-location-error">{showError}</small>
                )}
            </div>
        </div>
    );
};
