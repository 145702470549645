import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ProductCard } from '../../compositions';
import { ConnectedArticleDetailContentBlocks } from '../../connectors/ConnectedArticleDetailContentBlocks/ConnectedArticleDetailContentBlocks';
import { RelatedArticles, SubFooter } from '../../containers';
import { SubFooterFormType } from '../../entities/@blocks/SubFooter/SubFooter';
import { MOCK_ARTICLES, MOCK_PRODUCTS } from '../../mock/mock-data';

import './ArticleDetailPage.scss';

interface ArticleDetailProps {
    className?: string;
}

export const ArticleDetailPage: FC<ArticleDetailProps> = ({
    className = '',
}): ReactElement => {
    const products = [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS].map((product, index) => ({
        ...product,
        id: index.toString(),
    }));

    return (
        <Page className={`article-detail-page ${className}`}>
            <ConnectedArticleDetailContentBlocks />

            {/* @TODO: Replace with product grid composition? */}
            <Wrapper className="article-detail-page__block">
                <h4 className="article-detail-page__products-title">Shop the look</h4>
                <div className="article-detail-page__products-wrapper">
                    {products.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>
            </Wrapper>

            <RelatedArticles
                articles={MOCK_ARTICLES.slice(0, 3)}
                title="Gerelateerde blogs"
                className="article-detail-page__block"
            />

            <SubFooter
                title="Wekelijks inspiratie in je inbox?"
                text="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                backgroundColor="var(--c-green-25)"
                textColor="var(--c-black)"
                form={SubFooterFormType.newsletter}
                className="article-detail-page__block"
            />
        </Page>
    );
};
