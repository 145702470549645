import { FC, ReactElement, useState } from 'react';

import { Page, Wrapper } from '../../components';
import { TabList } from '../../compositions';
import { HighlightedArticle, RelatedArticles } from '../../containers';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { MOCK_ARTICLES } from '../../mock/mock-data';

import './InspirationPage.scss';

export const InspirationPage: FC = (): ReactElement => {
    /* TODO check how to data is received and replace dummy data */
    const tabs = [
        { label: 'Nieuws', value: 'tab-1' },
        { label: 'Trends', value: 'tab-2' },
        { label: 'Binnenkijkers', value: 'tab-3' },
        { label: 'Interviews', value: 'tab-4' },
        { label: 'Recepten', value: 'tab-5' },
        { label: 'Winkels', value: 'tab-6' },
        { label: 'Magazine', value: 'tab-7' },
    ];

    const tabContent: Record<string, ReactElement> = {
        'tab-1': (
            <>
                <RelatedArticles
                    articles={MOCK_ARTICLES}
                    className="inspiration-page__articles"
                />

                <HighlightedArticle
                    article={MOCK_ARTICLES[0]}
                    imagePosition={HorizontalAlignment.right}
                    className="inspiration-page__highlighted-article"
                />

                <RelatedArticles
                    articles={MOCK_ARTICLES}
                    className="inspiration-page__articles"
                />
            </>
        ),
        'tab-2': (
            <>
                <RelatedArticles
                    articles={MOCK_ARTICLES}
                    className="inspiration-page__articles"
                />
                <HighlightedArticle
                    article={MOCK_ARTICLES[1]}
                    imagePosition={HorizontalAlignment.right}
                    className="inspiration-page__highlighted-article"
                />
            </>
        ),
        'tab-3': (
            <div>
                <h2>Binnenkijkers content</h2>
                <p>Inhoud voor Binnenkijkers tab</p>
            </div>
        ),
    };

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const handleTabSelect = (value: string): void => {
        const selectedIndex = tabs.findIndex(tab => tab.value === value);

        setActiveIndex(selectedIndex || 0);
    };

    const activeTab = tabs[activeIndex].value;

    return (
        <Page className="inspiration-page">
            <HighlightedArticle
                article={MOCK_ARTICLES[2]}
                imagePosition={HorizontalAlignment.left}
                className="inspiration-page__highlighted-article"
            />

            <Wrapper className="inspiration-page__wrapper">
                <TabList
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabSelect={handleTabSelect}
                    className="inspiration-page__tabs"
                />
            </Wrapper>

            {tabContent[activeTab] || <div>Er is nog geen content beschikbaar.</div>}
        </Page>
    );
};
