import { InformationItem } from '../../entities/Information/InformationItem';

export const MOCK_INFORMATION_ITEMS: InformationItem[] = [
    {
        id: 1,
        icon: 'conversation',
        title: 'Informatiebalie winkels',
        html: `
            <p>
                Ook in onze winkels staan we voor je klaar, je vindt de informatiebalie bij de ingang van iedere winkel.
            </p>
        `,
    },
    {
        id: 2,
        icon: 'phone',
        title: 'Telefonisch contact',
        html: `
            <p>
                Helaas is het op dit moment niet mogelijk om ons telefonisch te bereiken. We helpen je graag!
            </p>
        `,
    },
    {
        id: 3,
        icon: 'smartphone',
        title: 'Contact via WhatsApp',
        html: `
            <p>
                Je kan ons altijd een WhatsApp bericht sturen. Van maandag t/m vrijdag tussen 10:00 en 17:00 uur 
                proberen we je vraag binnen 1 uur te beantwoorden. Stuur je buiten openingstijden een bericht? 
                Dan reageren we zodra we weer aanwezig zijn. Gaat je vraag over een order of bestelling? 
                Vermeld dan altijd je e-mailadres en ordernummer.
            </p>
        `,
    },
];
