import { FC, useState } from 'react';

import { Breadcrumbs, Page, Wrapper } from '../../components';
import { Pagination, ProductCard, ProductsSorting } from '../../compositions';
import { CategoryHeader, SeoProductsCategoryBlock } from '../../containers';
import { MOCK_IMAGE_7, MOCK_PRODUCTS } from '../../mock/mock-data';

import './ProductCategoryPage.scss';

export const ProductCategoryPage: FC = () => {
    const [paginationPage, setPaginationPage] = useState<number>(1);

    const products = [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS].map((product, index) => ({
        ...product,
        id: index.toString(),
    }));

    return (
        <Page className="product-category-page">
            <Wrapper>
                <Breadcrumbs
                    breadcrumbs={[
                        { title: 'Home', url: '/' },
                        { title: 'Verlichting', url: '/verlichting' },
                        { title: 'Tafel- & Bureaulampen', url: '' },
                    ]}
                />
            </Wrapper>

            <CategoryHeader
                title="Tafel- & bureaulampen"
                image={MOCK_IMAGE_7}
                className="category-page__image-text-block"
            />

            <Wrapper>
                <ProductsSorting className="product-category-page__products-sorting" />

                <div className="product-category-page__products-wrapper">
                    {products.map(product => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>

                <Pagination
                    amountOfPages={30}
                    currentPage={paginationPage}
                    onChange={setPaginationPage}
                    className="product-category-page__pagination"
                />

                <SeoProductsCategoryBlock
                    /* eslint-disable-next-line max-len */
                    productsDescription="De beste plafondlampen, hanglampen, wandlampen,
                vloerlampen, tafel- en bureaulampen, spots, kids verlochting en buitenverlichting vind je
                bij Loods 5! Lorem ipsum dolor sit amet consectetur. Pretium mattis arcu vulputate arcu in.
                 Amet orci ante gravida enim viverra. Urna pulvinar justo in ut. Adipiscing pretium suspendisse
                  vitae amet et id vitae at feugiat. Eget sed iaculis pharetra ipsum enim orci."
                />
            </Wrapper>
        </Page>
    );
};
