import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import { Wrapper } from '../../components';
import {
    Progress,
    SectionFooter,
    Slider,
    SliderProps,
} from '../../compositions';
import { Device } from '../../entities/Device/Device';
import { Link } from '../../entities/Link/Link';
import useDeviceWidth from '../../hooks/useDeviceWidth';

import './SliderSection.scss';

interface SliderSectionProps extends Pick<SliderProps, 'items' | 'columns'> {
    id: string;
    title: string;
    link?: Link;
    className?: string;
    sliderClassName?: string;
}

export const SliderSection: FC<SliderSectionProps> = ({
    id,
    title,
    items,
    columns,
    link,
    className = '',
    sliderClassName = '',
}): ReactElement => {
    const { device } = useDeviceWidth();

    const [activeArticleIndex, setActiveArticleIndex] = useState<number>(-1);

    const columnData = useMemo((): Record<Device, number> => ({
        [Device.mobile]: 1,
        [Device.tabletPortrait]: 2,
        [Device.tabletLandscape]: 3,
        [Device.desktop]: 4,
        ...columns,
    }), [columns]);

    const maxIndex = useMemo((): number => {
        if (!device) {
            return items.length;
        }

        return items.length - (columnData[device] - 1);
    }, [items, columnData, device]);

    return (
        <section className={`slider-section ${className}`}>
            <header className="slider-section__header">
                <Wrapper className="slider-section__header-wrapper">
                    <h2 className="slider-section__title">
                        {title}
                    </h2>

                    <Progress
                        total={maxIndex}
                        activeIndex={activeArticleIndex}
                        onChange={setActiveArticleIndex}
                        className="slider-section__progress"
                    />
                </Wrapper>
            </header>

            <Slider
                id={id}
                activeIndex={activeArticleIndex}
                maxIndex={maxIndex}
                items={items}
                columns={columnData}
                onChange={setActiveArticleIndex}
                className={`slider-section__slider ${sliderClassName}`}
            />

            {link && (
                <Wrapper className="slider-section__footer-wrapper">
                    <SectionFooter link={link} />
                </Wrapper>
            )}
        </section>
    );
};
