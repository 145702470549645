export interface MapProps {
    center?: LatLng;
    markers?: MapLocation[];
    zoom?: number;
    zoomControl?: boolean;
    onMarkerClick?: (location: MapLocation) => void;
    className?: string;
}

export interface LatLng {
    latitude: number;
    longitude: number;
}

export interface MapLocation {
    label: string;
    location: LatLng;
}

export interface ShopMapLocation extends MapLocation {
    address: string;
    routeLink?: string;
}

export interface OpenStreetMapSearchResult {
    features: {
        properties: {
            display_name: string;
        };
        geometry: {
            coordinates: [number, number];
        };
    }[];
}

export const defaultMapLatLng: LatLng = {
    latitude: 52.3531943,
    longitude: 5.2903855,
};

export const splitCharacter = ', ';
