import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';

import { Picture } from '../../../../../compositions';
import { Image } from '../../../../../entities/Media/Media';
import { Direction } from '../../../../../entities/Navigation/Navigation';

import './CarouselColumn.scss';

interface CarouselColumnProps {
    hasRequestedSlide: boolean;
    direction: Direction;
    currentImage: Image;
    newImage?: Image;
    onTransitionEnd?: () => void;
    className?: string;
}

export const CarouselColumn: FC<CarouselColumnProps> = ({
    hasRequestedSlide,
    direction,
    currentImage,
    newImage,
    onTransitionEnd,
    className = '',
}): ReactElement => {
    const [shouldTransition, setShouldTransition] = useState<boolean>(false);
    const [backgroundImage, setBackgroundImage] = useState<Image | undefined>(currentImage);

    useEffect((): void => {
        if (newImage && hasRequestedSlide) {
            setShouldTransition(true);
            setBackgroundImage(hasRequestedSlide ? newImage : currentImage);
        }
    }, [newImage, hasRequestedSlide]);

    const handleTransitionEnd = (): void => {
        setShouldTransition(false);

        if (onTransitionEnd) {
            onTransitionEnd();
        }
    };

    const carouselColumnClassNames = classNames('carousel-column', {
        [`carousel-column--reveal-${direction}`]: direction,
        'carousel-column--reveal-new': shouldTransition,
    }, className);

    return (
        <li className={carouselColumnClassNames}>
            <div className="carousel-column__wrapper">
                <Picture
                    {...currentImage}
                    className="carousel-column__picture carousel-column__picture--current"
                    imageClassName="carousel-column__image"
                />

                {newImage && (
                    <Picture
                        {...newImage}
                        onTransitionEnd={handleTransitionEnd}
                        className="carousel-column__picture carousel-column__picture--new"
                        imageClassName="carousel-column__image"
                    />
                )}

                {backgroundImage && (
                    <Picture
                        {...backgroundImage}
                        className="carousel-column__picture carousel-column__picture--background"
                        imageClassName="carousel-column__image"
                    />
                )}
            </div>
        </li>
    );
};
