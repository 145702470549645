import {
    CSSProperties,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Icon, IconName } from '../../../components';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import useElementSize from '../../../hooks/useElementSize';
import { LinkButton, LinkButtonProps } from '../..';

import './LinkIconButton.scss';

export interface LinkIconButtonProps extends LinkButtonProps {
    icon: IconName;
    iconPos?: HorizontalAlignment;
    hideLabel?: boolean;
    iconClassName?: string;
}

export const LinkIconButton: ForwardRefExoticComponent<LinkIconButtonProps> = forwardRef(({
    hasAnimation,
    icon,
    iconPos = HorizontalAlignment.left,
    text,
    hideLabel,
    className = '',
    iconClassName = '',
    labelClassName = '',
    ...linkButtonProps
}, ref: Ref<HTMLAnchorElement>): ReactElement => {
    const iconRef = useRef<SVGSVGElement>(null);
    const { width: iconWidth } = useElementSize(iconRef);

    const buttonClassNames = classNames('link-icon-button', {
        'link-icon-button--has-reveal-animation': hasAnimation && !hideLabel,
        'link-icon-button--has-push-animation': hasAnimation && hideLabel,
        [`link-icon-button--align-${iconPos}`]: iconPos,
    }, className);

    const cssVariables = {
        '--icon-button-icon-width': `${iconWidth}px`,
    } as CSSProperties;

    return (
        <LinkButton
            {...linkButtonProps}
            ref={ref}
            text={text}
            className={buttonClassNames}
            labelClassName={`link-icon-button__label ${labelClassName}`}
        >
            <div style={cssVariables} className="link-icon-button__icon-wrapper">
                <Icon
                    ref={iconRef}
                    name={icon}
                    className={`link-icon-button__icon ${iconClassName}`}
                />

                {hasAnimation && hideLabel && (
                    <Icon
                        name={icon}
                        className={`link-icon-button__icon link-icon-button__icon-copy ${iconClassName}`}
                    />
                )}
            </div>

            {!hideLabel && text}
        </LinkButton>
    );
});
