import { FC, ReactElement } from 'react';

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { InstantSearch as InstantSearchInstance } from 'react-instantsearch';

import { InstantSearch } from '../../containers';

const meiliSearchClient = {
    hostUrl: process.env.REACT_APP_MEILISEARCH_HOST_URL || 'missing-host-url',
    apiKey: process.env.REACT_APP_MEILISEARCH_API_KEY,
};

export const ConnectedInstantSearch: FC = (): ReactElement => {
    const { searchClient } = instantMeiliSearch(meiliSearchClient.hostUrl, meiliSearchClient.apiKey);

    const indexName: string = 'articles_en';

    return (
        <InstantSearchInstance indexName={indexName} searchClient={searchClient}>
            <InstantSearch />
        </InstantSearchInstance>
    );
};
