import { DefaultComponent } from '../Component/Component';

export type PageSectionInterface = DefaultComponent & unknown;

export enum ContentBlock {
    header = 'header',
    brandHeader = 'brandHeader',
    carousel = 'carousel',
    featuredProducts = 'featuredProducts',
    mediaGrid = 'mediaGrid',
    contentColumns = 'contentColumns',
}
