import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
    Button,
    Input,
    InputLabelWrapper,
    Picture,
} from '../../../compositions';
import { ConnectedShopLocator } from '../../../connectors/ConnectedShopLocator/ConnectedShopLocator';
import { SubFooterFormType, SubFooterInterface } from '../../../entities/@blocks/SubFooter/SubFooter';

import './SubFooter.scss';

interface SubFooterProps extends SubFooterInterface {
    className?: string;
}

// TODO: Replace with proper newsletter form component
const DummyNewsletterForm = () => (
    <form action="" style={{ display: 'flex', gap: '0.75rem' }}>
        <label
            aria-label="E-mailadres"
            className="text-input"
            style={{ flex: 1 }}
        >
            <InputLabelWrapper label="">
                <Input
                    hasUnderline
                    type="email"
                    enterKeyHint="go"
                    placeholder="E-mailadres"
                    autoComplete="email"
                    className="input-examples__input"
                />
            </InputLabelWrapper>
        </label>

        <Button
            type="submit"
            text="Aanmelden"
            isSmall
            className="dummy-news-letter-form__button"
        />
    </form>
);

export const SubFooter: FC<SubFooterProps> = ({
    backgroundImage,
    backgroundType,
    backgroundColor,
    textColor,
    title,
    text,
    image,
    showShopLocator,
    shopLocatorBackground,
    form,
    className = '',
}): ReactElement => {
    const subFooterClassNames = classNames('sub-footer', {
        [`sub-footer--has-background-${backgroundType}`]: backgroundType,
        'sub-footer--has-shop-locator': showShopLocator,
    }, className);

    const titleClassNames = classNames('sub-footer__title', {
        'sub-footer__title--large': showShopLocator,
    });

    const cssVariables = {
        ...backgroundImage ? { '--background-image': `url('${backgroundImage}')` } : {},
        ...backgroundColor ? { '--background-color': backgroundColor } : {},
        ...textColor ? { '--text-color': textColor } : {},
        ...shopLocatorBackground ? { '--shop-locator-background-color': shopLocatorBackground } : {},
    } as CSSProperties;

    return (
        <div style={cssVariables} className={subFooterClassNames}>
            <div className="sub-footer__wrapper">

                {(image || showShopLocator) && (
                    <div className="sub-footer__column">
                        {showShopLocator && (
                            <div className="sub-footer__shop-locator">
                                <ConnectedShopLocator />
                            </div>
                        )}

                        {image && (
                            <div className="sub-footer__media-wrapper">
                                <Picture
                                    {...image}
                                    className="sub-footer__picture"
                                    imageClassName="sub-footer__image"
                                />
                            </div>
                        )}
                    </div>
                )}

                {(title || text || form) && (
                    <div className="sub-footer__column">
                        <div className="sub-footer__column-inner">
                            {title && (
                                <h2 className={titleClassNames}>
                                    {title}
                                </h2>
                            )}

                            {text && (
                                <p className="sub-footer__text">
                                    {text}
                                </p>
                            )}

                            {form && (
                                <div className="sub-footer__form-wrapper">
                                    {form === SubFooterFormType.newsletter && (
                                        <DummyNewsletterForm />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};
