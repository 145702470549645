import { FC, ReactElement, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { Pagination } from '../../compositions';
import { ConnectedBrandContentBlocks } from '../../connectors/ConnectedBrandContentBlocks/ConnectedBrandContentBlocks';
import { ConnectedBrandProducts } from '../../connectors/ConnectedBrandProducts/ConnectedBrandProducts';
import { SubFooter } from '../../containers';
import { SubFooterFormType } from '../../entities/@blocks/SubFooter/SubFooter';
import { BackgroundType } from '../../entities/Background/BackgroundType';
import { MOCK_BRANDS } from '../../mock/mock-data/brand';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug } = useParams<{ slug: string }>();
    const [paginationPage, setPaginationPage] = useState<number>(1);

    const mockBrand = MOCK_BRANDS.find(brand => brand.slug === slug);

    if (!mockBrand || !slug) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <Page className={`brand-detail-page ${className}`}>
            <ConnectedBrandContentBlocks slug={slug} />

            <Wrapper className="brand-detail-page__block">
                <ConnectedBrandProducts slug={slug} />

                <Pagination
                    amountOfPages={30}
                    currentPage={paginationPage}
                    onChange={setPaginationPage}
                    className="brand-detail-page__pagination"
                />
            </Wrapper>

            <SubFooter
                title="Wekelijks inspiratie in je inbox?"
                text="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                backgroundImage="/mock/image/background-pattern-1.svg"
                backgroundType={BackgroundType.pattern}
                backgroundColor="var(--c-green-25)"
                textColor="var(--c-black)"
                form={SubFooterFormType.newsletter}
                className="brand-detail-page__block"
            />
        </Page>
    );
};
