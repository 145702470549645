import { combineSlices } from '@reduxjs/toolkit';

import appSlice, { AppState } from './app/appSlice';
import mapLocationSlice, { MapLocationState } from './mapLocation/mapLocationReducers';
import toastSlice, { ToastState } from './toast/toastSlice';

export interface Slices {
    appSlice: AppState;
    mapLocationSlice: MapLocationState;
    toastSlice: ToastState;
}

export default combineSlices(
    appSlice,
    mapLocationSlice,
    toastSlice,
);
