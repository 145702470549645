import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedCart } from '../../connectors';
import trans from '../../helpers/trans';

import './CartPage.scss';

// TODO: Implement design: https://endeavour-nl.atlassian.net/browse/L5W-83

export const CartPage: FC = (): ReactElement => (
    <Page className="cart-page">
        <Wrapper>
            <h1>
                {trans('common.cart')}
            </h1>

            <ConnectedCart />
        </Wrapper>
    </Page>
);
