/* eslint-disable max-len */
import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import { SvgIconProps } from '../../../entities/Svg/Svg';

export const IconPhone: ForwardRefExoticComponent<SvgIconProps> = forwardRef(({
    className = '',
    accentClassName = '',
}, ref: Ref<SVGSVGElement>): ReactElement => (
    <svg ref={ref} viewBox="0 0 26 27" className={`icon ${className}`}>
        <path
            className={accentClassName}
            d="M15.4 20L13.8 19.1C11.1 17.55 8.85 15.3 7.3 12.6L6.4 11L7.7 9.7L9.35 8.05L7.5 3.35L2.6 4.2C2.8 14.95 11.45 23.6 22.2 23.8L23.05 18.9L18.35 17.05L16.7 18.7L15.4 20ZM17.8 14.2L25.8 17.4L24.2 26.2H22.6C10.2 26.2 0.2 16.2 0.2 3.8V2.2L9 0.6L12.2 8.6L9.4 11.4C10.75 13.75 12.65 15.65 15 17L17.8 14.2Z"
        />
    </svg>
));
