import { FC } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { WorkingHours } from '../../entities/ServiceContactDetails/ServiceContactDetails';
import { getOpenStatus } from '../../helpers/workingHours';
import useTrans from '../../hooks/useTrans';

import './ContactOption.scss';

interface OpenStatusProps {
    linkText: string;
    linkUrl: string;
    workingHours?: (WorkingHours | null)[];
    className?: string;
}

export const ContactOption: FC<OpenStatusProps> = ({
    linkText,
    linkUrl,
    workingHours,
    className = '',
}) => {
    const trans = useTrans();

    const openStatus = getOpenStatus(workingHours);

    let openStatusText = trans('components.contactOption.closed');
    if (openStatus.openUntil === '24/7') {
        openStatusText = trans('components.contactOption.alwaysOpen');
    } else if (openStatus.isOpen && openStatus.openUntil) {
        openStatusText = trans('components.contactOption.openUntil', { time: openStatus.openUntil });
    }

    return (
        <div className={`contact-option ${className}`}>
            <Link
                to={linkUrl}
                target={linkUrl.startsWith('http') ? '_blank' : '_top'}
                rel={linkUrl.startsWith('http') ? 'noreferrer' : 'opener'}
                className="contact-option__link"
            >
                {linkText}
            </Link>
            <div
                className={classNames('contact-option__status', {
                    'contact-option__status--open': openStatus.isOpen,
                    'contact-option__status--closed': !openStatus.isOpen,
                })}
            >
                <span className="contact-option__status-indicator" />
                <span className="contact-option__status-text">
                    {openStatusText}
                </span>
            </div>
        </div>
    );
};
