import { FC, ReactElement, useState } from 'react';

import { CartSidebar } from '../../containers';
import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { CartItem } from '../../entities/Cart/Cart';
import { MOCK_PRODUCT_1 } from '../../mock/mock-data';

export const ConnectedCartSideBar: FC = (): ReactElement => {
    const cartData: CartItem[] = [
        {
            product: MOCK_PRODUCT_1,
            quantity: 1,
        },
    ];

    const totalPrice = cartData.reduce((acc, item) => acc + item.product.price * item.quantity, 0);

    const [removingCartItemId, setRemovingCartItemId] = useState<string | null>(null);

    // TODO: Fetch cart data from API
    // TODO: Add query to add product to cart
    // TODO: Add query to remove product to cart

    const handleRemoveFromCart = (productId: string) => {
        console.log('handleRemoveFromCart', productId);
        setRemovingCartItemId(null);
    };

    const mockUspItems: UspItem[] = [
        { id: 0, text: 'Online besteld? Gratis verzonden', icon: 'cart-truck' },
        { id: 1, text: 'Keuze uit meer dan 300 meubelmerken', icon: 'lamp' },
        { id: 2, text: 'Niet tevreden? Geld terug!', icon: 'happy' },
    ];

    return (
        <CartSidebar
            cartData={cartData}
            totalPrice={totalPrice}
            uspItems={mockUspItems}
            onRemoveFromCart={handleRemoveFromCart}
            removingCartItemId={removingCartItemId}
        />
    );
};
