import { FC } from 'react';

import { Product } from '../../entities/Product/Product';
import { Picture } from '../Picture/Picture';

import './ProductMedia.scss';

interface ProductMediaProps {
    product: Product;
    className?: string;
}

export const ProductMedia: FC<ProductMediaProps> = ({
    product,
    className = '',
}) => (
    <div className={`product-media ${className}`}>
        {product.images.map((image) => (
            <Picture
                key={image.src}
                {...image}
                className="product-media__picture"
                imageClassName="product-media__image"
            />
        ))}
    </div>
);
