import { useCallback, useState } from 'react';

import { LatLng } from '../entities/Map/Map';

interface GeolocationState {
    geolocation?: LatLng;
    error?: string;
}

export const useGeolocation = (): [GeolocationState, () => void] => {
    const [geolocationState, setGeolocationState] = useState<GeolocationState>({
        geolocation: undefined,
        error: undefined,
    });

    const triggerGeolocation = useCallback(() => {
        if (!navigator.geolocation) {
            setGeolocationState({ error: 'Geolocation is not supported by this browser.' });
            return;
        }

        const successCallback = (position: GeolocationPosition) => {
            const { latitude, longitude } = position.coords;
            setGeolocationState({ geolocation: { latitude, longitude } });
        };

        const errorCallback = (err: GeolocationPositionError) => {
            setGeolocationState({ error: `Error: ${err.message}.` });
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }, []);

    return [geolocationState, triggerGeolocation];
};

export default useGeolocation;
