import { FC, ReactElement, useMemo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useLocale } from './context';
import { AppRoute } from './entities/Routing/Routing';
import useTrans from './hooks/useTrans';
import {
    ArticleDetailPage,
    BrandDetailPage,
    CartPage,
    ContactPage,
    ContentBlocksPage,
    HomePage,
    HomeWithInteractiveProductHeaderPage,
    IndexPage,
    InspirationPage,
    NotFoundPage,
    ProductCategoryPage,
    ProductDetailPage,
    SearchPage,
    StyleguidePage,
} from './pages';

export interface RouteData<T = string> {
    key: T;
    label: string;
    path: string;
}

const routes = [
    { key: AppRoute.account, element: <NotFoundPage /> }, // TODO: Implement AccountPage
    { key: AppRoute.articleDetail, element: <ArticleDetailPage /> },
    { key: AppRoute.brandDetail, element: <BrandDetailPage /> },
    { key: AppRoute.cart, element: <CartPage /> },
    { key: AppRoute.contact, element: <ContactPage /> },
    { key: AppRoute.contentBlocks, element: <ContentBlocksPage /> },
    { key: AppRoute.home, element: <HomePage /> },
    { key: AppRoute.homeWithInteractiveProductHeader, element: <HomeWithInteractiveProductHeaderPage /> },
    { key: AppRoute.index, element: <IndexPage /> },
    { key: AppRoute.inspiration, element: <InspirationPage /> },
    { key: AppRoute.notFound, element: <NotFoundPage /> },
    { key: AppRoute.productCategory, element: <ProductCategoryPage /> },
    { key: AppRoute.productDetail, element: <ProductDetailPage /> },
    { key: AppRoute.search, element: <SearchPage /> },
    { key: AppRoute.styleguide, element: <StyleguidePage /> },
    { key: AppRoute.wishlist, element: <NotFoundPage /> }, // TODO: Implement WishlistPage
];

export const LocalizedRoutes: FC = (): ReactElement => {
    const { language } = useLocale();
    const trans = useTrans();

    // Get route paths and labels from translations, based on active language
    const localizedRoutes = useMemo(() => routes.map(route => {
        const routeData = {
            path: route.key !== AppRoute.notFound
                ? trans(`${route.key}.path`)
                : '*',
            label: trans(`${route.key}.label`),
        };

        return {
            ...route,
            ...routeData,
        };
    }), [language]);

    return (
        <Routes>
            {localizedRoutes.map(route => <Route {...route} key={route.key} />)}
        </Routes>
    );
};
