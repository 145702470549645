import { HorizontalAlignment } from '../../Alignment/Alignment';
import { BackgroundType } from '../../Background/BackgroundType';
import { Image } from '../../Media/Media';

export enum SubFooterFormType {
    newsletter = 'newsletter',
}

export interface SubFooterInterface {
    backgroundColor?: string;
    backgroundImage?: string;
    backgroundType?: BackgroundType;
    textColor?: string;
    title: string;
    text: string;
    image?: Image;
    imageAlignment?: HorizontalAlignment;
    form?: SubFooterFormType;
    showShopLocator?: boolean;
    shopLocatorBackground?: string;
}
