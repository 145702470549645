export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const convertNumberToCurrency = (
    number: number,
    includeCurrencySymbol: boolean,
    locale = 'nl-NL',
): string => number
    .toLocaleString(locale, {
        style: includeCurrencySymbol ? 'currency' : 'decimal',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    .replace(',00', ',-');
